import { Input } from "antd";
import { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "../Hooks/useQuery";
import { debounce } from "../Utils/HelperFunctions";
const { Search } = Input;

const SearchBar = () => {
  const history = useHistory();
  let query = useQuery();

  const [searchInput, setSearchInput] = useState("");

  const redirectToSearchPage = (event) => {
    setSearchInput(event.target.value);
    history.push(`/dashboard/results?search_query=${event.target.value}`);
  };

  const handleSearchChange = debounce(redirectToSearchPage, 300);

  const handleChange = (event) => {
    // to unmount searchPage componenet when there is no input value for better UX
    // triggered when allowClear is chosen
    if (event.target.value === "") {
      history.push(`/dashboard/`);
    }
  };

  return (
    <div style={{ width: "35%" }}>
      <Search
        placeholder="Search batch"
        onKeyUp={handleSearchChange}
        onChange={handleChange}
        allowClear
        defaultValue={searchInput || query.get("search_query")}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default memo(SearchBar);
