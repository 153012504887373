import React from "react";
import { Popover, Button, Space } from "antd";

const ActionConfirm = (props) => {
  const { title, Icon, batchAction, btnClassName } = props;

  const popoverContent = () => {
    return (
      <Space>
        <Button type="primary" onClick={() => batchAction()}>
          Confirm
        </Button>
      </Space>
    );
  };

  return (
    <Popover
      placement="right"
      title={title}
      trigger="click"
      content={() => popoverContent()}
    >
      <Button
        type="primary"
        className={btnClassName}
        icon={<Icon />}
        shape="circle"
      ></Button>
    </Popover>
  );
};

export default ActionConfirm;
