import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Col, Empty, Row, Table, Tag, Space, message, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BATCH_STATUS, EXECUTION_MODE, STATUS_COLOR } from "../Constants";
import { ROLE } from "../Constants/Roles";
import {
  EXECUTE_BATCH,
  generateApiURL,
  PAUSE_BATCH,
  RESUME_BATCH,
} from "../Constants/URLs";
import { useQuery } from "../Hooks/useQuery";
import { getAPI, postAPI } from "../Services/Api";
import { AuthContext } from "../Stores/AuthContext";
import { getDateTimeString } from "../Utils/HelperFunctions";
import {
  GET_ENVIRONMENT,
  GET_EXECUTION_MODE,
  GET_STATUS,
} from "../Utils/Utils";
import ActionConfirm from "./ActionConfirm";
const { Paragraph } = Typography;

const SearchPage = () => {
  const { userDetails } = useContext(AuthContext);
  const history = useHistory();
  const [queryValue, setQueryValue] = useState("");
  const [NoDataFound, setNoDataFound] = useState(false);
  const [batchDetails, setBatchDetails] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [totalBatchCount, setTotalBatchCount] = useState(0);
  const [refreshComponent, setRefreshComponent] = useState(false);
  let query = useQuery();

  useEffect(() => {
    if (query.get("search_query") === "") {
      history.push("/dashboard/");
      return () => resetData();
    }
    setQueryValue(query.get("search_query"));
  }, [query, history]);

  useEffect(() => {
    getBatches();
    // eslint-disable-next-line
  }, [query, refreshComponent]);

  const getBatches = (pageNumber = 1, pageSize = 10) => {
    setTableLoading(true);
    let params = {
      page: pageNumber,
      page_size: pageSize,
      field: query.get("search_query"),
    };
    getAPI(`/batch/`, params).then((response) => {
      setNoDataFound(!response.results.length);
      let results = response.results.map((item) => {
        item["key"] = item.id;
        item["environmentMode"] = GET_ENVIRONMENT[item.environment];
        item["executionMode"] = GET_EXECUTION_MODE[item.execution_mode];
        item["status_id"] = GET_STATUS[item.status];
        item["total_requests_count"] = item.meta_data.total_requests_count;
        return item;
      });
      setTableLoading(false);
      setBatchDetails(results);
      setTotalBatchCount(response.count);
    });
  };

  const batchActionHandler = (batchDetail, actionType) => {
    let url = generateApiURL(actionType, batchDetail.id);
    setTableLoading(true);

    postAPI(url)
      .then((res) => {
        if (res.response) {
          setRefreshComponent((prevState) => !prevState);
          let messageText = "";

          if (actionType === EXECUTE_BATCH) {
            messageText = `${batchDetail.name} is now running`;
          } else if (actionType === RESUME_BATCH) {
            messageText = `${batchDetail.name} is now resumed`;
          } else if (actionType === PAUSE_BATCH) {
            messageText = `${batchDetail.name} is now paused`;
          }
          message.success(messageText);
        }
      })
      .finally(() => setTableLoading(false));
  };

  const resetData = () => {
    setQueryValue("");
    setNoDataFound(false);
    setBatchDetails([]);
    setTableLoading(false);
    setTotalBatchCount(0);
  };

  const columnTitle = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <a href={`/batch/${record.id}`} target="_blank" rel="noreferrer">
            {text}
          </a>
        );
      },
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: "Action",
      key: "actions",
      render: (text, record) => {
        if (
          !(
            userDetails.role === ROLE.STAFF_USER ||
            userDetails.role === ROLE.SUPER_USER
          )
        ) {
          return null;
        }

        let batchActionDetails = null;
        if (
          record.status === BATCH_STATUS.PENDING &&
          text.execution_mode === EXECUTION_MODE.FIXED
        ) {
          batchActionDetails = {
            title: "Confirm to Execute",
            Icon: PlusCircleOutlined,
            btnClassName: "success-btn",
            action: EXECUTE_BATCH,
          };
        } else if (record.status === BATCH_STATUS["IN PROGRESS"]) {
          batchActionDetails = {
            title: "Confirm to Pause",
            Icon: PauseCircleOutlined,
            btnClassName: "",
            action: PAUSE_BATCH,
          };
        } else if (record.status === BATCH_STATUS.PAUSED) {
          batchActionDetails = {
            title: "Confirm to Resume",
            Icon: PlayCircleOutlined,
            btnClassName: "success-btn",
            action: RESUME_BATCH,
          };
        }
        return (
          batchActionDetails && (
            <Space>
              <ActionConfirm
                {...batchActionDetails}
                batchAction={() =>
                  batchActionHandler(text, batchActionDetails.action)
                }
              />
            </Space>
          )
        );
      },
      width: 90,
    },
    {
      title: "Batch Id",
      dataIndex: "id",
      key: "id",
      render: (data) => {
        return (
          <Paragraph copyable code>
            {data}
          </Paragraph>
        );
      },
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: "Request Source Type",
      dataIndex: "source_type",
      key: "source_type",
      responsive: ["xs", "sm", "md", "lg"],
      render: (text) => (
        <Tag color="cyan" key={text}>
          {text.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status_id",
      key: "status_id",
      render: (data) => (
        <Tag color={STATUS_COLOR[data]} key={data}>
          {data}
        </Tag>
      ),
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: "Environment",
      dataIndex: "environmentMode",
      key: "environmentMode",
      render: (data) => (
        <Tag color={STATUS_COLOR[data]} key={data}>
          {data}
        </Tag>
      ),
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: "Execution Mode",
      dataIndex: "executionMode",
      key: "executionMode",
      responsive: ["xs", "sm", "md", "lg"],
    },

    {
      title: "Total Requests",
      dataIndex: "total_requests_count",
      key: "total_requests_count",
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: "Created Requests",
      dataIndex: "request_counter",
      key: "request_counter",
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: "Completed Requests",
      dataIndex: "completed_requests_count",
      key: "completed_requests_count",
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => {
        return <p>{getDateTimeString(date)}</p>;
      },
      responsive: ["xs", "sm", "md", "lg"],
      defaultSortOrder: ["descend"],
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }}>
          <div className="margin-5">
            <h1 className="textAlign">You have searched for : {queryValue}</h1>

            {!NoDataFound ? (
              <Table
                dataSource={batchDetails}
                columns={columnTitle}
                bordered
                scroll={{ x: true }}
                loading={tableLoading}
                pagination={{
                  onChange: (page, pageSize) => getBatches(page, pageSize),
                  total: totalBatchCount,
                  showSizeChanger: true,
                  onShowSizeChange: (current, size) =>
                    getBatches(current, size),
                  showTotal: (total) => `Total results : ${total}`,
                }}
              />
            ) : (
              <Empty />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(SearchPage);
