import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Select, Input, message, Switch, Spin } from "antd";
import classes from "../../Dashboard/Dashboard.module.css";
import {
  BATCH_PRIORITY,
  FALCON,
  FEATURES,
  IMAGERY_MONTH,
  MONTHS,
  ORCHESTRATOR,
  PIPELINE,
  TARGET_SOURCE_TYPE,
  WINGS,
} from "../../Constants";
import { FileAddOutlined } from "@ant-design/icons";
import { CREATE_RERUN_BATCH_REQUEST } from "../../Constants/URLs";
import { postAPI, getAPI } from "../../Services/Api";
import {
  CREATE_BATCH_REQUEST,
  GET_WINGS_SOURCE_STATS,
  STEP_NAMES,
} from "../../Constants/URLs";
import { AuthContext } from "../../Stores/AuthContext";
import { ALL_TEMPLATES, TEMPLATES } from "../../Constants/Templates";
import { getENV } from "../../Utils/HelperFunctions";

const { Option } = Select;

const FixedBatch = (props) => {
  const { userDetails } = useContext(AuthContext);
  const {
    featureOptions,
    pipelineOptions,
    wingsSourceTypeOptions,
    teamsList,
    isRerun = false,
    setIsRerun,
    showButton = true,
    batchDetails,
    localOrchMetadata,
    requestIds,
  } = props;

  const [isInstantModalVisible, setIsInstantModalVisible] = useState(isRerun);
  const showInstantModal = () => setIsInstantModalVisible(true);

  const [instantRunDetails, setInstantRunDetails] = useState({
    name: "",
    environment: getENV().toString(),
    execution_mode: 1,
    source_type: "",
    user: userDetails.id,
    token: "",
    input_format: "",
  });

  const [sourceStats, setSourceStats] = useState({});

  // in future percentage will be an array
  const [batchNotification, setBatchNotification] = useState({
    flag: false,
    percentage: 70,
  });
  const [instantRunMetaData, setInstantRunMetaData] = useState({
    target_source_type: props.localOrchMetadata?.target_source_type,
    pipeline: props.localOrchMetadata?.pipeline,
    features: props.localOrchMetadata?.features,
    imagery_month: [],
    qr_batch: false,
    fms: false,
    priority: BATCH_PRIORITY[4],
    request_annotation_time: null,
  });
  const [instantRunFile, setInstantRunFile] = useState(null);
  const [instantConfig, setInstantConfig] = useState({
    max_requests: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [jobTime, setJobTime] = useState({
    hours: "",
    minutes: "",
  });

  const [stepsNames, setStepNames] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState({});
  const [stepsNamesLoading, setStepsNamesLoading] = useState(false);

  useEffect(() => {
    /**
     * The below code gets the meta data from localStorage and sets it in state variable as soon as component is mounted.
     */
    if (props.localOrchMetadata) {
      if (
        props.localOrchMetadata.features ||
        props.localOrchMetadata.pipeline ||
        props.localOrchMetadata.target_source_type
      ) {
        setInstantRunMetaData({
          ...props.localOrchMetadata,
          imagery_month: [],
          qr_batch: false,
          fms: false,
          priority: BATCH_PRIORITY[4],
        });
      }
    }
  }, [props.localOrchMetadata]);

  useEffect(() => {
    /**
     * The below code gets the List of the steps name in the selected pipeline
     */
    setSelectedTeams({});
    if (instantRunMetaData.pipeline) {
      setStepsNamesLoading(true);
      const env = getENV();
      postAPI(STEP_NAMES, {
        environment: env,
        pipeline_id: instantRunMetaData.pipeline,
      })
        .then((res) => {
          setStepNames([...res]);
        })
        .catch(() => setStepNames([]))
        .finally(() => setStepsNamesLoading(false));
    }
  }, [instantRunMetaData.pipeline]);

  useEffect(() => {
    if (isRerun) {
      setInstantRunDetails({
        name: `${batchDetails?.name}_QR_Lot${batchDetails?.rerun_count}`,
        environment: `${batchDetails?.environment}`,
        execution_mode: batchDetails?.execution_mode,
        source_type: batchDetails?.source_type,
        user: userDetails.id,
        token: batchDetails?.token,
        input_format: `${batchDetails?.input_format}`,
      });
      setInstantRunMetaData({
        target_source_type: localOrchMetadata?.target_source_type,
        pipeline: localOrchMetadata?.pipeline,
        features: localOrchMetadata?.features,
        qr_batch: true,
        fms: true,
        priority: batchDetails?.meta_data.priority,
      });
      setInstantConfig({
        max_requests: batchDetails?.config?.max_requests,
      });
      setIsInstantModalVisible(isRerun);
    }
  }, [isRerun]);

  useEffect(() => {
    if (instantRunDetails.environment) getSourceStats();
  }, [instantRunDetails.environment]);

  /**
   * @description This function is responsible for collecting the batch details entered in modal, and sending it to an create-batch-api endpoint.
   *
   * @returns void
   */
  const instantRunHandler = () => {
    setIsLoading(true);

    // A function to validate all the fields present in the modal.
    let validationError = validateFields();
    if (validationError.length > 0) {
      validationError.map((error) => message.error(error, 1));
      setIsLoading(false);
      return;
    }

    let teams = [];
    for (const key in selectedTeams) {
      teams = [...teams, { [`${key}`]: `${selectedTeams[key]}` }];
    }

    let orchMetaData = { ...instantRunMetaData, teams: teams };
    let hours = Number(jobTime.hours) * 60 * 60;
    let minutes = Number(jobTime.minutes) * 60;

    if (hours || minutes) {
      orchMetaData.request_annotation_time = hours + minutes;
    }

    // This is to convert the given data into multipart-form data
    let formData = new FormData();
    for (let key in instantRunDetails) {
      formData.append(key, instantRunDetails[key]);
    }
    if (isRerun) {
      formData.append("parent_batch_id", batchDetails.id);
      formData.append("request_ids", requestIds.toString());
    } else {
      formData.append("batch_file", instantRunFile);
    }
    formData.append("config", JSON.stringify(instantConfig));
    if (instantRunDetails.source_type.toLowerCase() !== ORCHESTRATOR) {
      if (batchNotification.flag) {
        formData.append(
          "meta_data",
          JSON.stringify({
            alerts: { percentage: parseFloat(batchNotification.percentage) },
          })
        );
      } else {
        formData.append("meta_data", JSON.stringify({}));
      }
    } else {
      if (batchNotification.flag) {
        formData.append(
          "meta_data",
          JSON.stringify({
            ...orchMetaData,
            alerts: { percentage: parseFloat(batchNotification.percentage) },
          })
        );
      } else {
        formData.append("meta_data", JSON.stringify(orchMetaData));
      }
    }
    // Makes an API call to /api/batch/ endpoint to create a batch
    const url = isRerun ? CREATE_RERUN_BATCH_REQUEST : CREATE_BATCH_REQUEST;
    postAPI(url, formData, { multipartFormData: true })
      .then((res) => {
        localStorage.setItem("meta-data", JSON.stringify(instantRunMetaData));
        !isRerun && props.setRefreshComponent((prevState) => !prevState);
        message.success("Batch Created");
        setIsLoading(false);
        resetState();
        // window.location.reload();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * @description This function validates the input feilds present in the modal form while creating a batch.
   *
   * @returns true if any error in validation false if everything is ok.
   */
  const validateFields = () => {
    let errorMessage = [];
    if (instantRunDetails.name === "") {
      errorMessage.push("Name is missing");
    }

    if (instantRunDetails.environment === "") {
      errorMessage.push("Environment is missing");
    }

    if (instantRunDetails.source_type === "") {
      errorMessage.push("Source type is missing");
    }
    if (instantConfig.max_requests === "") {
      errorMessage.push("Max Requests is missing");
    }
    if (instantRunDetails.input_format === "") {
      errorMessage.push("Input Format is missing");
    }
    if (
      instantRunDetails.source_type === ORCHESTRATOR &&
      (instantRunMetaData.target_source_type === undefined ||
        instantRunMetaData.pipeline === undefined)
    ) {
      errorMessage.push("Target Source type or Pipeline is missing");
    }

    if (
      instantRunDetails.source_type.toLowerCase() === ORCHESTRATOR &&
      instantRunMetaData.features.length === 0
    ) {
      errorMessage.push("Features are missing");
    }
    if (!isRerun && instantRunFile === null) {
      errorMessage.push("Please upload a file");
    }

    if (
      instantRunDetails.source_type === ORCHESTRATOR &&
      (Number(jobTime.hours) >= 25 || Number(jobTime.hours) < 0)
    ) {
      errorMessage.push("Please enter valid time in Meta Data section");
    }

    if (
      instantRunDetails.source_type === ORCHESTRATOR &&
      (Number(jobTime.minutes) > 60 || Number(jobTime.minutes) < 0)
    ) {
      errorMessage.push("Please enter valid time in Meta Data section");
    }
    return errorMessage;
  };

  /**
   * @description This function updates the meta-data state.
   *
   * @param {*} event : this is a dom node that we get
   * @param {*} type : this is a type for which we are updating the meta-data, there are many fields in meta-data, so this specifies which meta-data to be updated.
   */
  const metaDataChangeHandler = (event, type) => {
    setInstantRunMetaData((prevState) => ({
      ...prevState,
      [type]: Array.isArray(event) ? [...event] : event,
    }));
  };

  const templateHandler = (event, name) => {
    setTemplateLoading(true);
    setInstantRunDetails((prevState) => ({
      ...prevState,
      ...TEMPLATES[name].data,
    }));
    setInstantRunMetaData((prevState) => ({
      ...prevState,
      ...TEMPLATES[name].meta_data,
    }));
    setBatchNotification((prevState) => ({
      ...prevState,
      ...TEMPLATES[name].notification,
    }));
    setInstantConfig(TEMPLATES[name].config);
    setTemplateLoading(false);
    message.success(`${name} templated loaded`, 1);
  };

  const getSourceStats = async () => {
    try {
      const res = await getAPI(GET_WINGS_SOURCE_STATS, {
        environment: Number(instantRunDetails.environment),
      });
      setSourceStats(res);
    } catch (err) {
      console.log(err);
    }
  };
  /**
   * @description This function resets the react state variables.
   * It resets : modalVisible state, batchDetails, and batchConfig state.
   */
  const resetState = () => {
    setIsInstantModalVisible(false);
    setIsRerun && setIsRerun(false);
    setInstantRunDetails({
      name: "",
      environment: "",
      execution_mode: 1,
      source_type: "",
      user: userDetails.id,
      token: "",
      input_format: "",
    });
    setBatchNotification({
      flag: false,
      percentage: 70,
    });
    setInstantConfig({
      max_requests: "",
    });
    setSelectedTeams({});
  };

  /**
   * @description This is an array of React JSX elements which are rendered in Modal Footer.
   */
  const footerComponent = [
    <Button type="ghost" onClick={() => resetState()} key={"cancel-btn"}>
      Cancel
    </Button>,
    <Button
      type="primary"
      color="green"
      onClick={instantRunHandler}
      className="success-btn"
      key={"start-btn"}
      loading={isLoading}
    >
      Create
    </Button>,
  ];

  const handleTeamChange = (value, stepName) => {
    if (!value) {
      const teams = selectedTeams;
      if (selectedTeams.hasOwnProperty(`${stepName}`)) delete teams[stepName];
      setSelectedTeams({ ...teams });
    } else
      setSelectedTeams((prev) => ({
        ...prev,
        [stepName]: value,
      }));
  };

  return (
    <>
      {showButton && (
        <Button
          type="primary"
          className={classes.batchBtn}
          onClick={showInstantModal}
          icon={<FileAddOutlined />}
        >
          Instant
        </Button>
      )}
      <Modal
        title="Create Instant Batch"
        open={isInstantModalVisible}
        // onOk={() => {}}
        onCancel={() => resetState()}
        footer={footerComponent}
      >
        <section className={classes.modalContainer}>
          <div>
            <h2>Choose from a Template</h2>
            <Spin tip="Loading Template..." spinning={templateLoading}>
              <div className="fixed-template-container">
                {ALL_TEMPLATES.map((template) => (
                  <Button
                    key={template}
                    onClick={(event) => templateHandler(event, template)}
                  >
                    {template}
                  </Button>
                ))}
              </div>
            </Spin>
          </div>
          <h3 className={classes.modalInputTitle}>Enter Name of the Batch :</h3>
          <Input
            placeholder="Enter Name.."
            value={instantRunDetails.name}
            onChange={(e) =>
              setInstantRunDetails((prevState) => {
                return { ...prevState, name: e.target.value };
              })
            }
          />

          <h3 className={classes.modalInputTitle}>
            Enter Request Source Type :
          </h3>
          <Select
            style={{ width: "100%" }}
            value={instantRunDetails.source_type}
            onChange={(value) =>
              setInstantRunDetails((prevState) => {
                return { ...prevState, source_type: value };
              })
            }
          >
            <Option value="orchestrator">Orchestrator</Option>
            <Option value="falcon">Falcon</Option>
            <Option value="wings">WINGS</Option>
          </Select>

          <h3 className={classes.modalInputTitle}>Select Environment</h3>
          <Select
            style={{ width: "100%" }}
            value={instantRunDetails.environment}
            onChange={(value) =>
              setInstantRunDetails((prevState) => {
                return { ...prevState, environment: value };
              })
            }
          >
            <Option value="2">STAGE</Option>
            <Option value="3">PRODUCTION</Option>
            <Option value="4">QA</Option>
          </Select>

          <h3 className={classes.modalInputTitle}>Select Input Format</h3>
          <Select
            style={{ width: "100%" }}
            value={instantRunDetails.input_format}
            onChange={(value) =>
              setInstantRunDetails((prevState) => {
                return { ...prevState, input_format: value };
              })
            }
          >
            {instantRunDetails.source_type === FALCON ? (
              <>
                <Option value="1">ADDRESS</Option>
                <Option value="2">LAT_LON</Option>
              </>
            ) : instantRunDetails.source_type === ORCHESTRATOR ? (
              <>
                <Option value="3">GEOJSON</Option>
              </>
            ) : instantRunDetails.source_type === WINGS ? (
              <>
                <Option value="4">JSON</Option>
              </>
            ) : (
              <>
                <Option value="1">ADDRESS</Option>
                <Option value="2">LAT_LON</Option>
                <Option value="3">GEOJSON</Option>
                <Option value="4">JSON</Option>
              </>
            )}
          </Select>

          {instantRunDetails.source_type !== ORCHESTRATOR ? (
            <>
              <h3 className={classes.modalInputTitle}>
                Enter Token{" "}
                {instantRunDetails.source_type === ""
                  ? ""
                  : `for ${instantRunDetails.source_type}`}{" "}
                :
              </h3>
              <Input
                placeholder="Enter token.."
                value={instantRunDetails.token}
                onChange={(e) =>
                  setInstantRunDetails((prevState) => {
                    return { ...prevState, token: e.target.value };
                  })
                }
              />
            </>
          ) : null}

          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className={classes.modalInputTitle}>
              {" "}
              Do you want us to notify you on slack?{" "}
            </h3>
            <Switch
              size="small"
              style={{ marginLeft: "1rem", marginTop: "0.4rem" }}
              checked={batchNotification.flag}
              onChange={(value) =>
                setBatchNotification((prevState) => ({
                  ...prevState,
                  flag: value,
                }))
              }
            />
          </div>

          {batchNotification.flag && (
            <>
              <h3 className={classes.modalInputTitle}>
                {" "}
                Enter at which % we should notify you :{" "}
              </h3>
              <Input
                type="text"
                value={batchNotification.percentage}
                onChange={(e) =>
                  setBatchNotification((prevState) => ({
                    ...prevState,
                    percentage: e.target.value,
                  }))
                }
              />
            </>
          )}

          <h3 className={classes.modalInputTitle}>Enter Max Requests :</h3>
          <Input
            placeholder="Enter Max Requests.."
            value={instantConfig.max_requests}
            type="number"
            onChange={(e) =>
              setInstantConfig((prevState) => {
                return {
                  ...prevState,
                  max_requests: parseInt(e.target.value),
                };
              })
            }
          />

          {instantRunDetails.source_type === ORCHESTRATOR ? (
            <>
              <h3 className={classes.modalInputTitle}>Choose Priority :</h3>
              <Select
                style={{ width: 120 }}
                value={instantRunMetaData.priority}
                onChange={(value) =>
                  setInstantRunMetaData((prevState) => {
                    return { ...prevState, priority: parseInt(value) };
                  })
                }
              >
                {BATCH_PRIORITY.map((item) => (
                  <Option key={item}>{item}</Option>
                ))}
              </Select>
              <h2 className={classes.modalInputTitle}>
                Meta Data for Orchestrator :
              </h2>

              <h3 className={classes.modalInputTitle}>
                Enter WINGS Source Type :
              </h3>

              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Select WINGS Source type"
                onChange={(event) =>
                  metaDataChangeHandler(event, TARGET_SOURCE_TYPE)
                }
                defaultValue={props.localOrchMetadata?.target_source_type}
                checked={instantRunMetaData.target_source_type}
                value={instantRunMetaData.target_source_type}
              >
                {Object.entries(sourceStats).map(([key, value]) => {
                  return (
                    <Option key={key} value={key}>
                      <div className={classes.wingsSourceTypeContainer}>
                        {key}
                        <span>
                          {value &&
                            `${value.running_requests}/${value.total_requests}`}
                        </span>
                      </div>
                    </Option>
                  );
                })}
              </Select>

              <h3 className={classes.modalInputTitle}>Enter Pipeline :</h3>

              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Select Pipeline"
                onChange={(event) => metaDataChangeHandler(event, PIPELINE)}
                defaultValue={props.localOrchMetadata?.pipeline}
                value={instantRunMetaData.pipeline}
              >
                {pipelineOptions.map((pipeline) => (
                  <Option key={pipeline} value={pipeline}>
                    {pipeline}
                  </Option>
                ))}
              </Select>

              <h3 className={classes.modalInputTitle}>Select Teams :</h3>
              <div className={classes.stepNameBox}>
                {stepsNamesLoading ? (
                  <Spin />
                ) : (
                  <>
                    {stepsNames.map((item) => (
                      <div className={classes.stepNameDiv}>
                        <div className={classes.stepNameHeading}>{item} :</div>
                        <Select
                          allowClear
                          className={classes.fullWidth}
                          placeholder="Choose team"
                          onChange={(event) => handleTeamChange(event, item)}
                          value={selectedTeams[item]}
                        >
                          {teamsList.map((team) => (
                            <Option key={team.id} value={team.id}>
                              {team.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    ))}
                  </>
                )}
              </div>

              <h3 className={classes.modalInputTitle}>Select Features :</h3>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select Features"
                onChange={(event) => metaDataChangeHandler(event, FEATURES)}
                defaultValue={props.localOrchMetadata?.features}
                value={instantRunMetaData.features}
              >
                {featureOptions.map((feature) => (
                  <Option key={feature} value={feature}>
                    {feature}
                  </Option>
                ))}
              </Select>

              <h3 className={classes.modalInputTitle}>
                Select month imagery :
              </h3>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="You can choose multiple months"
                value={instantRunMetaData.imagery_month}
                onChange={(event) =>
                  metaDataChangeHandler(event, IMAGERY_MONTH)
                }
              >
                {Object.keys(MONTHS).map((month) => (
                  <Option key={month} value={MONTHS[month]}>
                    {month}
                  </Option>
                ))}
              </Select>

              {instantRunMetaData.fms && (
                <div>
                  <div>
                    <h3 className={classes.modalInputTitle}>
                      Single Job Time in Batch
                    </h3>
                    <span>
                      Hours{" "}
                      <small>(enter between 0 & 24, both inclusive)</small>{" "}
                    </span>
                    <Input
                      type="number"
                      value={jobTime.hours}
                      onChange={(e) =>
                        setJobTime((prevState) => ({
                          ...prevState,
                          hours: e.target.value,
                        }))
                      }
                    />

                    {Number(jobTime.hours) < 0 && (
                      <small style={{ color: "red" }}>
                        Please enter between 0 & 24
                      </small>
                    )}
                    {Number(jobTime.hours) > 24 && (
                      <small style={{ color: "red" }}>
                        Please enter between 0 & 24
                      </small>
                    )}
                  </div>

                  <div>
                    <span>
                      Minutes{" "}
                      <small>(enter between 0 & 60, both inclusive)</small>{" "}
                    </span>
                    <Input
                      type="number"
                      value={jobTime.minutes}
                      onChange={(e) =>
                        setJobTime((prevState) => ({
                          ...prevState,
                          minutes: e.target.value,
                        }))
                      }
                    />

                    {Number(jobTime.minutes) < 0 && (
                      <small style={{ color: "red" }}>
                        Please enter between 0 & 60
                      </small>
                    )}
                    {Number(jobTime.minutes) > 60 && (
                      <small style={{ color: "red" }}>
                        Please enter between 0 & 60
                      </small>
                    )}
                  </div>
                </div>
              )}

              <div></div>

              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", margin: "1rem 0" }}>
                  <h3 style={{ marginRight: "0.5rem" }}>QR Batch </h3>
                  <Switch
                    checked={instantRunMetaData.qr_batch}
                    size="small"
                    onChange={(value) =>
                      setInstantRunMetaData((prevState) => ({
                        ...prevState,
                        qr_batch: value,
                      }))
                    }
                    style={{ marginTop: "0.4rem" }}
                  />
                </div>

                <div style={{ display: "flex", margin: "1rem" }}>
                  <h3 style={{ marginRight: "0.5rem" }}>FMS </h3>
                  <Switch
                    checked={instantRunMetaData.fms}
                    size="small"
                    onChange={(value) =>
                      setInstantRunMetaData((prevState) => ({
                        ...prevState,
                        fms: value,
                      }))
                    }
                    style={{ marginTop: "0.4rem" }}
                  />
                </div>
              </div>

              {/* <div style={{ display: "flex", margin: "1rem 0" }}>
                <h3 style={{ marginRight: "0.5rem" }}>FMS </h3>
                <Switch
                  value={instantRunMetaData.fms}
                  onChange={(value) =>
                    setInstantRunMetaData((prevState) => ({
                      ...prevState,
                      fms: value,
                    }))
                  }
                />
              </div> */}
            </>
          ) : null}

          {isRerun ? (
            <>
              <h3 className={classes.modalInputTitle}>
                Requests Already Selected{" "}
              </h3>
            </>
          ) : (
            <>
              <h3 className={classes.modalInputTitle}>Upload Data</h3>
              <input
                type="file"
                accept=".csv , .json , .geojson"
                onChange={(e) => setInstantRunFile(e.target.files[0])}
              />
            </>
          )}
        </section>
      </Modal>
    </>
  );
};

export default React.memo(FixedBatch);
