import { Button, Row, Col, Tooltip } from "antd";
import React, { useContext } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import classes from "./Navbar.module.css";
import { AuthContext } from "../../Stores/AuthContext";

import logo from "../../Assets/logo.svg";
import SearchBar from "../SearchBar";

const Navbar = () => {
  const {
    isAuthenticated,
    logoutHandler,
    userDetails: user,
  } = useContext(AuthContext);

  return (
    <nav className={classes.navbar}>
      <Row className={classes.rowContainer}>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }}>
          <div className={classes.colContainer}>
            {/* LOGO Component */}
            <div className={classes.navLeft}>
              <a href="/">
                <img
                  src={logo}
                  alt="Attentive Logo"
                  className={classes.navLogo}
                />
              </a>
            </div>

            {isAuthenticated && <SearchBar />}

            {/* Logout Component */}
            <div className={classes.navRight}>
              {isAuthenticated && (
                <>
                  <div>
                    <>{user.email}</>
                  </div>
                  <Tooltip placement="bottom" title="Logout">
                    <Button
                      type="primary"
                      danger
                      shape="circle"
                      onClick={logoutHandler}
                      icon={<LogoutOutlined />}
                    ></Button>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </nav>
  );
};

export default Navbar;
