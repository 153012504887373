import React from "react";

const ErrorMessage = ({ field, message }) => {
  return (
    <>
      {field && (
        <span>
          <p>Present in :</p> <p style={{ color: "red" }}>{field}</p>
        </span>
      )}
      {message && (
        <span style={{ color: "red" }} >
          {message}
        </span>
      )}
    </>
  );
};

export default ErrorMessage;
