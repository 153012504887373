import React, { useState, useEffect, useContext } from "react";

import {
  Button,
  Modal,
  Select,
  Input,
  DatePicker,
  TimePicker,
  message,
  Space,
  Switch,
} from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import classes from "../../Dashboard/Dashboard.module.css";
import { PlusOutlined } from "@ant-design/icons";
import {
  FALCON,
  FEATURES,
  IMAGERY_MONTH,
  MONTHS,
  ORCHESTRATOR,
  PIPELINE,
  TARGET_SOURCE_TYPE,
  WINGS,
} from "../../Constants";
import { postAPI } from "../../Services/Api";
import { CREATE_BATCH_REQUEST } from "../../Constants/URLs";
import { AuthContext } from "../../Stores/AuthContext";

// eslint-disable-next-line
const { RangePicker } = DatePicker;
const { Option } = Select;

const ScheduledBatch = (props) => {
  const {
    featureOptions,
    pipelineOptions,
    wingsSourceTypeOptions,
    setRefreshComponent,
  } = props;

  const { userDetails } = useContext(AuthContext);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);

  const showScheduleModal = () => setIsScheduleModalVisible(true);

  const [scheduledRunDetails, setScheduledRunDetails] = useState({
    name: "",
    environment: "",
    execution_mode: 2,
    source_type: "",
    user: userDetails.id,
    token: "",
    input_format: "",
    alerts: {
      flag: false,
      percentage: 70,
    },
  });
  const [scheduleRunMetaDetails, setScheduleRunMetaDetails] = useState({
    target_source_type: props.localOrchMetadata?.target_source_type,
    pipeline: props.localOrchMetadata?.pipeline,
    features: props.localOrchMetadata?.features,
    imagery_month: [],
    qr_batch: false,
    fms: false,
  });
  const [scheduledRunFile, setScheduledRunFile] = useState(null);
  const [scheduleConfig, setScheduleConfig] = useState([
    { to_time: "", from_time: "", max_requests: "", date: "" },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.localOrchMetadata) {
      setScheduleRunMetaDetails({
        ...props.localOrchMetadata,
        imagery_month: [],
        qr_batch: false,
        fms: false,
      });
    }
  }, [props.localOrchMetadata]);

  const handleScheduleConfigChange = (index, value, changeProp) => {
    let values = [...scheduleConfig];

    if (changeProp === "time") {
      values[index].to_time = value[1];
      values[index].from_time = value[0];
      setScheduleConfig([...values]);
      return;
    }

    values[index][changeProp] = value;
    setScheduleConfig([...values]);
  };

  /**
   * @description This function validates the input feilds present in the modal form while creating a batch.
   *
   * @returns true if any error in validation false if everything is ok.
   */
  const validateFields = () => {
    let errorMessage = [];
    if (scheduledRunDetails.name === "") {
      errorMessage.push("Name is missing");
    }
    if (scheduledRunDetails.environment === "") {
      errorMessage.push("Environment is missing");
    }
    if (scheduledRunDetails.source_type === "") {
      errorMessage.push("Source type is missing");
    }
    if (scheduledRunDetails.input_format === "") {
      errorMessage.push("Input Format is missing");
    }
    if (
      scheduledRunDetails.source_type.toLowerCase() === ORCHESTRATOR &&
      (scheduleRunMetaDetails.target_source_type === undefined ||
        scheduleRunMetaDetails.pipeline === undefined)
    ) {
      errorMessage.push("Target Source type or Pipeline is missing");
    }

    if (
      scheduledRunDetails.source_type.toLowerCase() === ORCHESTRATOR &&
      scheduleRunMetaDetails.features.length === 0
    ) {
      errorMessage.push("Features are missing");
    }
    if (scheduledRunFile === null) {
      errorMessage.push("Please upload a file");
    }
    return errorMessage;
  };

  const createScheduleBatch = () => {
    setIsLoading(true);

    let validationError = validateFields();
    if (validationError.length > 0) {
      validationError.map((error) => message.error(error));
      setIsLoading(false);
      return;
    }

    let finalScheduleConfig = {};
    scheduleConfig.forEach((item) => {
      if (item.date in finalScheduleConfig) {
        finalScheduleConfig = {
          ...finalScheduleConfig,
          [item.date]: [
            ...finalScheduleConfig[item.date],
            {
              from_time: item.from_time,
              to_time: item.to_time,
              max_requests: item.max_requests,
            },
          ],
        };
      } else {
        finalScheduleConfig = {
          ...finalScheduleConfig,
          [item.date]: [
            {
              from_time: item.from_time,
              to_time: item.to_time,
              max_requests: item.max_requests,
            },
          ],
        };
      }
    });

    let formData = new FormData();

    for (let key in scheduledRunDetails) {
      formData.append(key, scheduledRunDetails[key]);
    }

    formData.append("config", JSON.stringify(finalScheduleConfig));
    formData.append("batch_file", scheduledRunFile);

    if (scheduledRunDetails.source_type.toLowerCase() !== "orchestrator") {
      if (scheduledRunDetails.alerts.flag) {
        formData.append(
          "meta_data",
          JSON.stringify({
            alerts: {
              percentage: parseFloat(scheduledRunDetails.alerts.percentage),
            },
          })
        );
      } else formData.append("meta_data", JSON.stringify({}));
    } else {
      if (scheduledRunDetails.alerts.flag) {
        formData.append(
          "meta_data",
          JSON.stringify({
            ...scheduleRunMetaDetails,
            alerts: {
              percentage: parseFloat(scheduledRunDetails.alerts.percentage),
            },
          })
        );
      } else
        formData.append("meta_data", JSON.stringify(scheduleRunMetaDetails));
    }

    postAPI(CREATE_BATCH_REQUEST, formData, { multipartFormData: true })
      .then((res) => {
        setRefreshComponent((prevState) => !prevState);
        localStorage.setItem(
          "meta-data",
          JSON.stringify(scheduleRunMetaDetails)
        );
        message.success("Batch Created");
        setIsLoading(false);
        resetState();
        // window.location.reload();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addNewScheduleUI = () => {
    let timeFormat = "HH:mm";
    let dateFormat = "DD-MM-YYYY";
    return scheduleConfig.map((item, index) => {
      return (
        <div key={index}>
          <h3 className={classes.modalInputTitle}>
            Enter Max Requests for Schedule {index + 1} :
          </h3>
          <Input
            placeholder="Enter Max Requests.."
            value={item.max_requests}
            type="number"
            onChange={(e) =>
              handleScheduleConfigChange(
                index,
                parseInt(e.target.value),
                "max_requests"
              )
            }
          />

          <h3 className={classes.modalInputTitle}>Schedule Date and Time :</h3>
          <Space size={38} wrap style={{ width: "100%" }}>
            <DatePicker
              format={dateFormat}
              onChange={(date, dateString) =>
                handleScheduleConfigChange(index, dateString, "date")
              }
            />
            <TimePicker.RangePicker
              format={timeFormat}
              style={{ width: "100%" }}
              onChange={(date, dateString) =>
                handleScheduleConfigChange(index, dateString, "time")
              }
            />
          </Space>
        </div>
      );
    });
  };

  /**
   * @description This function updates the meta-data state.
   *
   * @param {*} event : this is a dom node that we get
   * @param {*} type : this is a type for which we are updating the meta-data, there are many fields in meta-data, so this specifies which meta-data to be updated.
   */
  const metaDataChangeHandler = (event, type) => {
    setScheduleRunMetaDetails((prevState) => ({
      ...prevState,
      [type]: Array.isArray(event) ? [...event] : event,
    }));
  };

  const resetState = () => {
    setIsScheduleModalVisible(false);
    setScheduledRunDetails({
      name: "",
      environment: "",
      execution_mode: 1,
      source_type: "",
      user: userDetails.id,
      token: "",
      input_format: "",
      alerts: {
        flag: false,
        percentage: 70,
      },
    });
    setScheduleConfig([
      {
        to_time: "",
        from_time: "",
        max_requests: "",
        date: "",
      },
    ]);
  };

  const footerComponent = [
    <Button type="ghost" onClick={() => resetState()} key="cancel-btn">
      Cancel
    </Button>,
    <Button
      type="primary"
      color="green"
      onClick={() => createScheduleBatch()}
      className="success-btn"
      key="schedule-btn"
      loading={isLoading}
    >
      Create
    </Button>,
  ];

  return (
    <>
      <Button
        type="primary"
        className={classes.batchBtn}
        onClick={showScheduleModal}
        icon={<FileAddOutlined />}
      >
        Schedule
      </Button>

      {/* Scheduled Modal */}
      <Modal
        title="Create Scheduled Batch"
        visible={isScheduleModalVisible}
        onOk={() => {}}
        onCancel={() => resetState()}
        footer={footerComponent}
      >
        <div className={classes.modalContainer}>
          <h3 className={classes.modalInputTitle}>Enter Name of the Batch :</h3>
          <Input
            placeholder="Enter Name.."
            value={scheduledRunDetails.name}
            onChange={(e) =>
              setScheduledRunDetails((prevState) => {
                return { ...prevState, name: e.target.value };
              })
            }
          />

          <h3 className={classes.modalInputTitle}>
            Enter Request Source Type :
          </h3>
          <Select
            style={{ width: "100%" }}
            value={scheduledRunDetails.source_type}
            onChange={(value) =>
              setScheduledRunDetails((prevState) => {
                return { ...prevState, source_type: value };
              })
            }
          >
            <Option value="orchestrator">Orchestrator</Option>
            <Option value="falcon">Falcon</Option>
            <Option value="wings">WINGS</Option>
          </Select>

          <h3 className={classes.modalInputTitle}>Select Environment</h3>
          <Select
            style={{ width: "100%" }}
            value={scheduledRunDetails.environment}
            onChange={(value) =>
              setScheduledRunDetails((prevState) => {
                return { ...prevState, environment: value };
              })
            }
          >
            <Option value="1">LOCAL</Option>
            <Option value="2">STAGE</Option>
            <Option value="3">PRODUCTION</Option>
            {scheduledRunDetails.source_type === "wings" && (
              <Option value="4">QA</Option>
            )}
          </Select>

          <h3 className={classes.modalInputTitle}>Select Input Format</h3>
          <Select
            style={{ width: "100%" }}
            value={scheduledRunDetails.input_format}
            onChange={(value) =>
              setScheduledRunDetails((prevState) => {
                return { ...prevState, input_format: value };
              })
            }
          >
            {scheduledRunDetails.source_type === FALCON ? (
              <>
                <Option value="1">ADDRESS</Option>
                <Option value="2">LAT_LON</Option>
              </>
            ) : scheduledRunDetails.source_type === ORCHESTRATOR ? (
              <>
                <Option value="3">GEOJSON</Option>
              </>
            ) : scheduledRunDetails.source_type === WINGS ? (
              <>
                <Option value="4">JSON</Option>
              </>
            ) : (
              <>
                <Option value="1">ADDRESS</Option>
                <Option value="2">LAT_LON</Option>
                <Option value="3">GEOJSON</Option>
              </>
            )}
          </Select>

          {scheduledRunDetails.source_type !== ORCHESTRATOR ? (
            <>
              <h3 className={classes.modalInputTitle}>
                Enter token{" "}
                {scheduledRunDetails.source_type === ""
                  ? ""
                  : `for ${scheduledRunDetails.source_type}`}{" "}
                :
              </h3>
              <Input
                placeholder="Enter token.."
                value={scheduledRunDetails.token}
                onChange={(e) =>
                  setScheduledRunDetails((prevState) => {
                    return { ...prevState, token: e.target.value };
                  })
                }
              />
            </>
          ) : null}

          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className={classes.modalInputTitle}>
              {" "}
              Do you want us to notify you on slack?{" "}
            </h3>
            <Switch
              size="small"
              style={{ marginLeft: "1rem", marginTop: "0.4rem" }}
              checked={scheduledRunDetails.alerts.flag}
              onChange={(value) =>
                setScheduledRunDetails((prevState) => ({
                  ...prevState,
                  alerts: {
                    flag: value,
                    percentage: 50,
                  },
                }))
              }
            />
          </div>

          {scheduledRunDetails.alerts.flag && (
            <>
              <h3 className={classes.modalInputTitle}>
                {" "}
                Enter at which % we should notify you :{" "}
              </h3>
              <Input
                type="number"
                value={scheduledRunDetails.alerts.percentage}
                onChange={(e) =>
                  setScheduledRunDetails((prevState) => {
                    return {
                      ...prevState,
                      alerts: {
                        flag: true,
                        percentage: e.target.value,
                      },
                    };
                  })
                }
              />
            </>
          )}

          {addNewScheduleUI()}
          <br />

          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() =>
              setScheduleConfig((prevState) => [
                ...prevState,
                { to: "", from: "", date: "", max_requests: "" },
              ])
            }
          >
            Add New Schedule
          </Button>

          {scheduledRunDetails.source_type === ORCHESTRATOR ? (
            <>
              <h2 className={classes.modalInputTitle}>
                Meta Data for Orchestrator :
              </h2>

              <h3 className={classes.modalInputTitle}>
                Enter Target Source Type :
              </h3>

              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Select WINGS Source type"
                onChange={(event) =>
                  metaDataChangeHandler(event, TARGET_SOURCE_TYPE)
                }
                defaultValue={props.localOrchMetadata?.target_source_type}
                checked={scheduleRunMetaDetails.target_source_type}
                value={scheduleRunMetaDetails.target_source_type}
              >
                {wingsSourceTypeOptions.map((sourceType) => (
                  <Option key={sourceType} value={sourceType}>
                    {sourceType}
                  </Option>
                ))}
              </Select>

              <h3 className={classes.modalInputTitle}>Enter Pipeline :</h3>

              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Select Pipeline"
                onChange={(event) => metaDataChangeHandler(event, PIPELINE)}
                defaultValue={props.localOrchMetadata?.pipeline}
                value={scheduleRunMetaDetails.pipeline}
              >
                {pipelineOptions.map((pipeline) => (
                  <Option key={pipeline} value={pipeline}>
                    {pipeline}
                  </Option>
                ))}
              </Select>

              <h3 className={classes.modalInputTitle}>Select Features :</h3>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select Features"
                onChange={(event) => metaDataChangeHandler(event, FEATURES)}
                defaultValue={props.localOrchMetadata?.features}
                value={scheduleRunMetaDetails.features}
              >
                {featureOptions.map((feature) => (
                  <Option key={feature} value={feature}>
                    {feature}
                  </Option>
                ))}
              </Select>

              <h3 className={classes.modalInputTitle}>
                Select month imagery :
              </h3>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="You can choose multiple months"
                value={scheduleRunMetaDetails.imagery_month}
                onChange={(event) =>
                  metaDataChangeHandler(event, IMAGERY_MONTH)
                }
              >
                {Object.keys(MONTHS).map((month) => (
                  <Option key={month} value={MONTHS[month]}>
                    {month}
                  </Option>
                ))}
              </Select>

              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", margin: "1rem 0" }}>
                  <h3 style={{ marginRight: "0.5rem" }}>QR Batch </h3>
                  <Switch
                    checked={scheduleRunMetaDetails.qr_batch}
                    size="small"
                    onChange={(value) =>
                      setScheduleRunMetaDetails((prevState) => ({
                        ...prevState,
                        qr_batch: value,
                      }))
                    }
                    style={{ marginTop: "0.4rem" }}
                  />
                </div>

                <div style={{ display: "flex", margin: "1rem" }}>
                  <h3 style={{ marginRight: "0.5rem" }}>FMS </h3>
                  <Switch
                    checked={scheduleRunMetaDetails.fms}
                    size="small"
                    onChange={(value) =>
                      setScheduleRunMetaDetails((prevState) => ({
                        ...prevState,
                        fms: value,
                      }))
                    }
                    style={{ marginTop: "0.4rem" }}
                  />
                </div>
              </div>
            </>
          ) : null}

          <h3 className={classes.modalInputTitle}>Upload CSV Data</h3>
          <input
            type="file"
            accept=".csv , .json , .geojson"
            onChange={(e) => setScheduledRunFile(e.target.files[0])}
          />
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ScheduledBatch);
