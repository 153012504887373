import { Button, Input, message, Modal } from "antd";
import React, { useState, memo } from "react";
import { string, func } from "prop-types";

const QRComments = ({ id, defaultComment, updateQR }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState(defaultComment);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  const resetData = () => {
    setIsModalOpen(false);
    setSubmitBtnLoading(false);
  };

  const handleSubmit = () => {
    setSubmitBtnLoading(true);
    if (comment === "") {
      message.error("Please add a comment before submitting.");
      resetData();
      return;
    }

    updateQR(id, 0, comment, resetData);
  };

  const inputTextHandler = (e) => setComment(e.target.value);

  const modalTitle = (
    <p className="margin-0">
      Add QR comment to <code>{id}</code> ID
    </p>
  );

  const footerComponent = (
    <>
      <Button onClick={closeModal}>Close</Button>
      <Button type="primary" onClick={handleSubmit} loading={submitBtnLoading}>
        Submit
      </Button>
    </>
  );

  return (
    <div className="mt-2">
      <Button type="primary" ghost onClick={() => setIsModalOpen(true)}>
        {defaultComment ? "Update comment" : "Add comment"}
      </Button>

      <Modal
        title={modalTitle}
        visible={isModalOpen}
        onCancel={closeModal}
        footer={footerComponent}
      >
        <div>
          <Input.TextArea
            rows={4}
            placeholder="Add your comment here.."
            value={comment}
            defaultValue={defaultComment}
            onChange={inputTextHandler}
          />
        </div>
      </Modal>
    </div>
  );
};

QRComments.propTypes = {
  id: string,
  defaultComment: string,
  updateQR: func,
};

QRComments.defaultProps = {
  defaultComment: "",
};

export default memo(
  QRComments,
  (prevProps, nextProps) =>
    prevProps.defaultComment === nextProps.defaultComment
);
