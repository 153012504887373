import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import { AuthContext } from "../Stores/AuthContext";

const ProtectedRoute = ({ component: Component, ...otherProps }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...otherProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default ProtectedRoute;
