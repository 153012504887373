import { BATCH_ENVIRONMENT } from "../Constants";
import JSZip from "jszip";

/**
 * @name getDateString
 * @function
 * @description Get date string from timestamp
 * @param {int} timestamp timestamp
 */
export const getDateTimeString = (timestamp) => {
  const date = new Date(timestamp);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (parseInt(hours) < 10) {
    hours = `0${hours}`;
  }
  if (parseInt(minutes) < 10) {
    minutes = `0${minutes}`;
  }
  return `${day} ${month} ${year}  ${hours}:${minutes}`;
};

export const getDateString = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  return `${year}-${month}-${day}`;
};

export const getAuthToken = () => localStorage.getItem("auth");

export const debounce = (cb, timeOut = 300) => {
  let timer;
  return (...args) => {
    let context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      cb.apply(context, args);
    }, timeOut);
  };
};

export const showTitle = (title) => <b>{title}</b>;

export const getTimeInHoursAndMins = (secs) => {
  let date = new Date(null);
  date.setSeconds(secs);
  return date.toISOString().substr(11, 8);
};

export const formatCurrentDate = () => {
  return (
    new Date().toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }) +
    " at " +
    new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })
  );
};
export const downloadImage = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], "image.tiff", { type: "image/tiff" });
    return file;
  } catch (error) {
    console.error("Error downloading image:", error);
  }
};

export const downloadSingleFile = async (data) => {
  const files = [];
  if (data) {
    if (data?.pipeline_run.modified_parcel) {
      files.push({
        name: "boundary_geojson",
        content: data?.pipeline_run?.modified_parcel,
      });
    } else {
      files.push({
        name: "boundary_geojson",
        content: data?.input?.boundary_geojson,
      });
    }
    if (data?.pipeline_run?.status != "4") {
      Object.keys(data?.input?.features).forEach((layer) => {
        files.push({
          name: `${layer}.geojson`,
          content: { type: "FeatureCollection", features: [] },
        });
      });
    } else {
      const layers = data?.input?.features;
      const layersData =
        data?.pipeline_run?.steps?.[data.pipeline_run.steps.length - 1]?.output;
      Object.keys(layers).forEach((layer) => {
        files.push({
          name: `${layer}.geojson`,
          content: layersData?.[layer],
        });
      });
    }
  }
  const zip = new JSZip();
  files.forEach(function (file) {
    zip.file(file.name, JSON.stringify(file.content));
  });
  if (data?.pipeline_run?.tiff_image_url) {
    zip.file(
      "image.tiff",
      await downloadImage(data?.pipeline_run?.tiff_image_url)
    );
  }
  zip.generateAsync({ type: "blob" }).then(function (blob) {
    const link = document.createElement("a");
    link.download = `file.zip`;
    link.href = URL.createObjectURL(blob);
    link.click();
    link.remove();
  });
};

export const getENV = () => {
  if (process.env.REACT_APP_ENV === "prod")
    return Number(BATCH_ENVIRONMENT["PRODUCTION"]);
  else return Number(BATCH_ENVIRONMENT["STAGE"]);
};
