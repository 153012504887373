import {
  BATCH_ENVIRONMENT,
  BATCH_INPUT_FORMAT,
  ORCHESTRATOR,
  ORCHESTRATOR_FEATURES,
} from ".";

const CASE_DATA = {
  data: {
    environment: BATCH_ENVIRONMENT.PRODUCTION,
    source_type: ORCHESTRATOR,
    input_format: BATCH_INPUT_FORMAT.GEOJSON,
  },
  notification: {
    flag: true,
    percentage: 80,
  },
  config: {
    max_requests: 100,
  },
};

const SOURCE_TYPE = "fms_dummy_images";
const PIPELINE = "FMSDummyImageRun";

export const HARDSCAPE = "Case Hardscape";
export const SOFTSCAPE = "Case Softscape";
export const ALL_FEATURES = "Case All Features";
export const MCS_FEATURES = "MCS All Features";
export const BRIGHTVIEW_FEATURES = "Brightview All Features";

export const CASE_HARDSCAPE = {
  ...CASE_DATA,
  meta_data: {
    target_source_type: SOURCE_TYPE,
    pipeline: PIPELINE,
    features: [
      ORCHESTRATOR_FEATURES.LOT_AREA,
      ORCHESTRATOR_FEATURES.PRIVATE_SIDEWALK,
      ORCHESTRATOR_FEATURES.PUBLIC_SIDEWALK,
    ],
    fms: true,
  },
};

export const CASE_SOFTSCAPE = {
  ...CASE_DATA,
  meta_data: {
    target_source_type: SOURCE_TYPE,
    pipeline: PIPELINE,
    features: [
      ORCHESTRATOR_FEATURES.TOTAL_TURF,
      ORCHESTRATOR_FEATURES.MULCH_BED,
      ORCHESTRATOR_FEATURES.GRAVEL_BED,
      ORCHESTRATOR_FEATURES.TREE,
      ORCHESTRATOR_FEATURES.PALM_TREE,
    ],
    fms: true,
  },
};

export const CASE_ALL_FEATURES = {
  ...CASE_DATA,
  meta_data: {
    target_source_type: SOURCE_TYPE,
    pipeline: PIPELINE,
    features: [
      ORCHESTRATOR_FEATURES.TOTAL_TURF,
      ORCHESTRATOR_FEATURES.MULCH_BED,
      ORCHESTRATOR_FEATURES.GRAVEL_BED,
      ORCHESTRATOR_FEATURES.TREE,
      ORCHESTRATOR_FEATURES.PALM_TREE,
      ORCHESTRATOR_FEATURES.LOT_AREA,
      ORCHESTRATOR_FEATURES.PRIVATE_SIDEWALK,
      ORCHESTRATOR_FEATURES.PUBLIC_SIDEWALK,
    ],
    fms: true,
  },
};

export const MCS_ALL_FEATURES = {
  ...CASE_DATA,
  meta_data: {
    target_source_type: SOURCE_TYPE,
    pipeline: PIPELINE,
    features: [
      ORCHESTRATOR_FEATURES.TOTAL_TURF,
      ORCHESTRATOR_FEATURES.MULCH_BED,
      ORCHESTRATOR_FEATURES.GRAVEL_BED,
      ORCHESTRATOR_FEATURES.SECONDARY_MOW,
      ORCHESTRATOR_FEATURES.ROUGH_MOW,
      ORCHESTRATOR_FEATURES.HEDGE,
      ORCHESTRATOR_FEATURES.PAVEMENT,
      ORCHESTRATOR_FEATURES.ROAD,
      ORCHESTRATOR_FEATURES.PONDS,
      ORCHESTRATOR_FEATURES.DECIDUOUS_TREE,
      ORCHESTRATOR_FEATURES.PALM_TREE,
      ORCHESTRATOR_FEATURES.TREE_RING,
      ORCHESTRATOR_FEATURES.SHRUBS,
      ORCHESTRATOR_FEATURES.PARKING_SPOT,
      ORCHESTRATOR_FEATURES.PUBLIC_SIDEWALK,
      ORCHESTRATOR_FEATURES.PRIVATE_SIDEWALK,
    ],
  },
};

export const BRIGHTVIEW_ALL_FEATURES = {
  ...CASE_DATA,
  meta_data: {
    target_source_type: SOURCE_TYPE,
    pipeline: PIPELINE,
    features: [
      ORCHESTRATOR_FEATURES.LAWN,
      ORCHESTRATOR_FEATURES.MULCH_BED,
      ORCHESTRATOR_FEATURES.GRAVEL_BED,
      ORCHESTRATOR_FEATURES.ROAD,
      ORCHESTRATOR_FEATURES.PARKING_SPOT,
      ORCHESTRATOR_FEATURES.SIDEWALK,
      ORCHESTRATOR_FEATURES.PAVEMENT,
      ORCHESTRATOR_FEATURES.HEDGE,
    ],
  },
};

export const ALL_TEMPLATES = [
  HARDSCAPE,
  SOFTSCAPE,
  ALL_FEATURES,
  MCS_FEATURES,
  BRIGHTVIEW_FEATURES,
];

export const TEMPLATES = {
  [HARDSCAPE]: CASE_HARDSCAPE,
  [SOFTSCAPE]: CASE_SOFTSCAPE,
  [ALL_FEATURES]: CASE_ALL_FEATURES,
  [MCS_FEATURES]: MCS_ALL_FEATURES,
  [BRIGHTVIEW_FEATURES]: BRIGHTVIEW_ALL_FEATURES,
};
