export const LOCAL = "LOCAL";
export const STAGE = "STAGE";
export const PRODUCTION = "PRODUCTION";
export const QA = "QA";

export const FIXED = "FIXED";
export const SCHEDULED = "SCHEDULED";

export const PENDING = "PENDING";
export const IN_PROGRESS = "IN PROGRESS";
export const COMPLETED = "COMPLETED";
export const FAILED = "FAILED";
export const PAUSED = "PAUSED";
export const ALL = "ALL";

//QR Status

export const ON_HOLD = "ON_HOLD";
export const RERUN = "RERUN";
export const DONE = "DONE";

export const ORCHESTRATOR = "orchestrator";
export const FALCON = "falcon";
export const WINGS = "wings";
export const STATUS_TEXT = "OK";
export const TARGET_SOURCE_TYPE = "target_source_type";
export const PIPELINE = "pipeline";
export const FEATURES = "features";
export const IMAGERY_MONTH = "imagery_month";
export const IN_PROGRESS_ = "IN_PROGRESS";

export const TRUE = "True";
export const FALSE = "False";

export const JSON_ = "json";
export const CSV = "csv";

export const LOW = "LOW";
export const MEDIUM = "MEDIUM";
export const HIGH = "HIGH";

export const FILTER = {
  STATUS: "STATUS",
  QR: "QR",
  DATE: "DATE",
};

export const BATCH_ENVIRONMENT = {
  [LOCAL]: "1",
  [STAGE]: "2",
  [PRODUCTION]: "3",
};

export const BATCH_INPUT_FORMAT = {
  ADDRESS: "1",
  LAT_LON: "2",
  GEOJSON: "3",
  JSON: "4",
};

export const EXECUTION_MODE = {
  [FIXED]: 1,
  [SCHEDULED]: 2,
};

export const STATUS_COLOR = {
  [IN_PROGRESS]: "gold",
  [PAUSED]: "purple",
  [FAILED]: "red",
  [PENDING]: "magenta",
  [COMPLETED]: "green",
  [PRODUCTION]: "lime",
  [STAGE]: "geekblue",
  [LOCAL]: "cyan",
};
export const QR_STATUS_COLOR = {
  [RERUN]: "purple",
  [DONE]: "green",
  [ON_HOLD]: "gold",
};

export const BATCH_STATUS = {
  [PENDING]: 1,
  [IN_PROGRESS]: 2,
  [PAUSED]: 5,
  [COMPLETED]: 3,
  [FAILED]: 4,
};

export const MONTHS = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

export const REQUESTS_STATUS = {
  [PENDING]: 1,
  [IN_PROGRESS_]: 2,
  [COMPLETED]: 3,
  [FAILED]: 4,
  [ALL]: 5,
};

export const REQUEST_STATUS_TEXT = {
  [PENDING]: "PENDING",
  [IN_PROGRESS_]: "IN PROGRESS",
  [COMPLETED]: "COMPLETED",
  [FAILED]: "FAILED",
  [ALL]: "ALL",
};

export const BATCH_PRIORITY = Array.from({ length: 100 }, (_, i) => i + 1);

export const ORCHESTRATOR_FEATURES = {
  BEDS: "beds",
  BUILDING: "building",
  DECIDUOUS_TREE: "deciduous_tree",
  DRIVABLE_AREA: "drivable_area",
  DRIVEWAY: "driveway",
  GRAVEL_BED: "gravel_bed",
  HARD_EDGE: "hard_edge",
  HEDGE: "hedge",
  LAWN: "lawn",
  LAWN_EDGE: "lawn_edge",
  LOT_AREA: "lot_area",
  MOWER_21: "mower_21",
  MOWER_36: "mower_36",
  MOWER_48: "mower_48",
  MOWER_60: "mower_60",
  MULCH_BED: "mulch_bed",
  NATIVE_AREA: "native_area",
  PALM_TREE: "palm_tree",
  PARCEL: "parcel",
  PARKING_SPOT: "parking_spot",
  PAVEMENT: "pavement",
  PLAYGROUND: "playground",
  PONDS: "ponds",
  PRIVATE_SIDEWALK: "private_sidewalk",
  PUBLIC_SIDEWALK: "public_sidewalk",
  ROAD: "road",
  ROUGH_MOW: "rough_mow",
  SECONDARY_MOW: "secondary_mow",
  SIDEWALK: "sidewalk",
  SOFT_EDGE: "soft_edge",
  SPORTS_FIELD: "sports_field",
  TOTAL_TURF: "total_turf",
  TREE: "tree",
  TREE_RING: "tree_ring",
  WATER_BODY: "water_body",
  SHRUBS: "shrubs_count",
};
export const QR_STATUS_ENUMS = {
  HOLD: 2,
  PASSED: 3,
  RERUN: 4,
};

export const TO_BE_DONE = 1; // added for backward compatibility with old data

export const QR_STATUS = {
  [TO_BE_DONE]: "TO BE DONE",
  [QR_STATUS_ENUMS.RERUN]: "RERUN",
  [QR_STATUS_ENUMS.HOLD]: "HOLD",
  [QR_STATUS_ENUMS.PASSED]: "PASSED",
};
