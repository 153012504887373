import {
  LOCAL,
  STAGE,
  PRODUCTION,
  FIXED,
  SCHEDULED,
  PENDING,
  IN_PROGRESS,
  COMPLETED,
  FAILED,
  PAUSED,
  ALL,
  QA,
} from "../Constants";

export const GET_ENVIRONMENT = {
  1: LOCAL,
  2: STAGE,
  3: PRODUCTION,
  4: QA,
};

export const GET_EXECUTION_MODE = {
  1: FIXED,
  2: SCHEDULED,
};

export const GET_STATUS = {
  1: PENDING,
  2: IN_PROGRESS,
  3: COMPLETED,
  4: FAILED,
  5: PAUSED,
};

export const BATCH_DETAILS = [
  {
    name: "Running Batches",
    id: "running_batches",
    key: 1,
    batchName: "executingBatches",
    status: 2,
    batchId: [IN_PROGRESS],
  },
  {
    name: "Paused Batches",
    id: "paused_batches",
    key: 2,
    batchName: "pausedBatches",
    status: 5,
    batchId: [PAUSED],
  },
  {
    name: "Completed Batches",
    id: "completed_batches",
    key: 3,
    batchName: "completedBatches",
    status: 3,
    batchId: [COMPLETED],
  },
  {
    name: "Failed Batches",
    id: "failed_batches",
    key: 4,
    batchName: "failedBatches",
    status: 4,
    batchId: [FAILED],
  },
  {
    name: "All Batches",
    id: "all_batches",
    key: 5,
    batchName: "allBatches",
    status: null,
    batchId: [ALL],
  },
];

export const RUNNING_BATCHES = "executingBatches";
export const PAUSED_BATCHES = "pausedBatches";
export const COMPLETED_BATCHES = "completedBatches";
export const FAILED_BATCHES = "failedBatches";
export const ALL_BATCHES = "allBatches";
export const PENDING_BATCHES = "pendingBatches";
export const IN_PROGRESS_BATCHES = "executingBatches";

export const GET_BATCH_TYPE = {
  [IN_PROGRESS]: RUNNING_BATCHES,
  [PAUSED]: PAUSED_BATCHES,
  [COMPLETED]: COMPLETED_BATCHES,
  [FAILED]: FAILED_BATCHES,
  [ALL]: ALL_BATCHES,
  [PENDING]: PENDING_BATCHES,
};
