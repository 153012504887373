import { Button, Modal, Input, Tabs, Tooltip, Space, message } from "antd";
import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  generateApiURL,
  UPDATE_MAX_PARALLEL_REQUESTS,
} from "../Constants/URLs";
import { patchAPI, postAPI } from "../Services/Api";

const { TabPane } = Tabs;

const EditSettings = (props) => {
  const [isEditRequestsModalOpen, setIsEditRequestsModalOpen] = useState(false);
  const [maxRequestsButton, setMaxRequestsButton] = useState(false);
  const [editNotifyButton, setEditNotifyButton] = useState(false);

  /**
   * @description <actual description here> and this function is used in <EditSecttings /> Component, and is passed as a prop.
   * @param {*} event : HTML DOM Node
   */
  const editMaxRequestsHandler = () => {
    if (parseInt(props.maxRequests) < 1) {
      message.error("Max Parallel Requests can't be less than 1");
      return;
    }
    setMaxRequestsButton(true);

    let payload = {
      max_requests: parseInt(props.maxRequests),
    };

    let url = generateApiURL(
      UPDATE_MAX_PARALLEL_REQUESTS,
      props.batchDetails.id
    );

    //TODO: Check the response here
    postAPI(url, payload)
      .then((res) => {
        setIsEditRequestsModalOpen(false);
        setMaxRequestsButton(false);
        window.location.reload();
      })
      .catch((err) => {
        setIsEditRequestsModalOpen(false);
        setMaxRequestsButton(false);
      });
  };

  const editNotificationPercentagehHandler = () => {
    setEditNotifyButton(true);
    if (
      props.notificationPercentage === undefined ||
      props.notificationPercentage === ""
    ) {
      message.error("Enter a notification %");
      setEditNotifyButton(false);
      return;
    }
    if (isNaN(props.notificationPercentage)) {
      message.error("Please Enter a valid notification %");
      setEditNotifyButton(false);
      return;
    }

    if (parseFloat(props.notificationPercentage) <= 0) {
      message.error("You can't set the % to be a less than 1.");
      setEditNotifyButton(false);
      return;
    }

    if (parseFloat(props.notificationPercentage) > 100) {
      message.error("You can't set the % more than 100");
      setEditNotifyButton(false);
      return;
    }
    let url = `/batch/${props.batchDetails.id}/`;
    let payload = {
      percentage: parseFloat(props.notificationPercentage),
    };
    patchAPI(url, payload, {})
      .then((res) => {
        setEditNotifyButton(false);

        if (res.response) {
          message.success(
            `Notification % has been changed to ${props.notificationPercentage}`
          );
        }
      })
      .catch((err) => setEditNotifyButton(false));
  };

  return (
    <>
      <Tooltip title="Edit Settings">
        <EditOutlined
          onClick={() => setIsEditRequestsModalOpen(true)}
          style={{ color: "#4D4D4D", fontSize: "18px" }}
        />
      </Tooltip>
      <Modal
        title="Edit Settings"
        visible={isEditRequestsModalOpen}
        onCancel={() => setIsEditRequestsModalOpen(false)}
        width={750}
        footer={[
          <Button onClick={() => setIsEditRequestsModalOpen(false)}>
            Cancel
          </Button>,
        ]}
      >
        <Tabs tabPosition="left" defaultActiveKey="1" type="card">
          <TabPane tab="Max requests" key="1">
            <div>
              <h4>Edit Max Parallel Requests </h4>
              <Space>
                <Input
                  type="number"
                  value={props.maxRequests}
                  placeholder="Change Max Parallel Requests"
                  onChange={(event) => props.setMaxRequests(event.target.value)}
                />
                <Button
                  type="primary"
                  onClick={editMaxRequestsHandler}
                  loading={maxRequestsButton}
                >
                  Update
                </Button>
              </Space>
            </div>
          </TabPane>
          <TabPane tab="Notification %" key="2">
            <div>
              <h4>Define % Batch Completed Thresold</h4>
              <Space>
                <Input
                  type="text"
                  value={props.notificationPercentage}
                  placeholder="Add number only"
                  onChange={(event) =>
                    props.setNotificationPercentage(event.target.value)
                  }
                />
                <Button
                  type="primary"
                  onClick={editNotificationPercentagehHandler}
                  loading={editNotifyButton}
                >
                  Update
                </Button>
              </Space>
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default React.memo(EditSettings);
