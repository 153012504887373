import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Button,
  Tabs,
  Space,
  Typography,
  message,
  Tag,
  Tooltip,
  DatePicker,
} from "antd";
import {
  ReloadOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons/lib/icons";
import Table from "../Components/Table";
import classes from "./Dashboard.module.css";
import FixedBatch from "../Components/Fixedbatch/FixedBatch";
import ScheduledBatch from "../Components/ScheduledBatch/ScheduledBatch";
import NoDataFound from "../Components/NoDataFound";
import {
  getDateString,
  getDateTimeString,
  getENV,
} from "../Utils/HelperFunctions";
import FailedRequestsModal from "./FailedRequestsModal";
import ConvertGeojsonToCSV from "./ConvertGeojsonToCSV";

import {
  GET_ENVIRONMENT,
  GET_EXECUTION_MODE,
  GET_STATUS,
  BATCH_DETAILS,
  GET_BATCH_TYPE,
  PAUSED_BATCHES,
  IN_PROGRESS_BATCHES,
  PENDING_BATCHES,
} from "../Utils/Utils";
import { getAPI, postAPI } from "../Services/Api";
import {
  EXECUTE_BATCH,
  generateApiURL,
  GET_ORCHESTRATOR_METADATA,
  LIST_ALL_BATCHES,
  PAUSE_BATCH,
  RESUME_BATCH,
  TEAMS_NAMES,
} from "../Constants/URLs";

import {
  ALL,
  BATCH_STATUS,
  COMPLETED,
  FAILED,
  IN_PROGRESS,
  PAUSED,
  PENDING,
  STATUS_COLOR,
} from "../Constants";
import ActionConfirm from "../Components/ActionConfirm";
import RBAC from "../Components/HOC/RBAC";
import { ROLE } from "../Constants/Roles";
import CheckFileUploadModal from "./CheckFileUploadModal";
const { TabPane } = Tabs;
const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const [refreshComponent, setRefreshComponent] = useState(true);
  const [batchDetails, setBatchDetails] = useState({
    allBatches: [],
    pendingBatches: [],
    pausedBatches: [],
    completedBatches: [],
    failedBatches: [],
    executingBatches: [],
  });

  const [orchestratorMetaData, setOrchestratorMetaData] = useState({
    features: [],
    pipelines: [],
    target_source_types: [],
  });

  const [localMetaDataOrch, setLocalMetaDataOrch] = useState({
    features: [],
    pipeline: "",
    target_source_type: "",
  });

  const [totalBatchCount, setTotalBatchCount] = useState({
    executingBatches: 0,
    pausedBatches: 0,
    completedBatches: 0,
    failedBatches: 0,
    allBatches: 0,
    pendingBatches: 0,
  });

  const [tableLoading, setTableLoading] = useState(false);
  const [startEndDate, setStartEndDate] = useState();
  const [startEndDateVal, setStartEndDateVal] = useState({
    toDate: null,
    fromDate: null,
  });
  const [currentActiveTab, setCurrentActiveTab] = useState(1);
  const [resetStartEndDate, setResetStartEndDate] = useState(false);
  const [dateRangeBtnLoading, setDateRangeBtnLoading] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    getBatches();
    getBatches(PENDING);
    getBatches(IN_PROGRESS);
    getBatches(PAUSED);
    getBatches(COMPLETED);
    getBatches(FAILED);
  }, [refreshComponent]);

  useEffect(() => {
    fetchMetaData();
    fetchTeamsList();
    let orchMetadata = JSON.parse(localStorage.getItem("meta-data"));

    setLocalMetaDataOrch({
      features: orchMetadata?.features,
      pipeline: orchMetadata?.pipeline,
      target_source_type: orchMetadata?.target_source_type,
    });
  }, []);

  const getLatestDataFromServer = () => {
    setRefreshComponent((prevState) => !prevState);
  };

  /**
   * @description This function gets all the orch meta-data from an api endpoint.
   */
  const fetchMetaData = () => {
    getAPI(GET_ORCHESTRATOR_METADATA).then((res) => {
      setOrchestratorMetaData({
        features: res.features,
        pipelines: res.pipelines,
        target_source_types: res.target_source_types,
      });
    });
  };

  const fetchTeamsList = () => {
    const env = getENV();
    postAPI(TEAMS_NAMES, { environment: env }).then((res) => {
      setTeams([...res]);
    });
  };
  /**
   * @description This function is responsible for fetching all the batches. Request method is GET and endpoint is /api/batch/
   * We pass status in params : this defines the status of a batch. So, we can fetch batches w.r.t a particular status as well.
   */
  const getBatches = (
    status = null,
    pageNumber = 1,
    pageSize = 10,
    toDate = null,
    fromDate = null,
    dateRange = false
  ) => {
    setTableLoading(true);
    let params = {
      page: pageNumber,
      page_size: pageSize,
      to: toDate,
      from: fromDate,
      status: status ? BATCH_STATUS[status] : null,
    };

    getAPI(LIST_ALL_BATCHES, params)
      .then((res) => {
        let batchType = status ? GET_BATCH_TYPE[status] : GET_BATCH_TYPE[ALL];
        if (res.results.length === 0) {
          if (dateRange) {
            message.info("No batches found");
          }
          setTableLoading(false);
          return;
        }
        setTotalBatchCount((prevState) => ({
          ...prevState,
          [batchType]: res.count,
        }));

        let response = res.results.map((item) => {
          item["key"] = item.id;
          item["environmentMode"] = GET_ENVIRONMENT[item.environment];
          item["executionMode"] = GET_EXECUTION_MODE[item.execution_mode];
          item["status_id"] = GET_STATUS[item.status];
          item["total_requests_count"] = item.meta_data.total_requests_count;
          return item;
        });

        setBatchDetails((prevState) => ({
          ...prevState,
          [batchType]: response,
        }));
        setTableLoading(false);
      })
      .finally(() => setTableLoading(false));
  };

  const getColumnTitles = (batchName) => {
    const columnTitle = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => {
          return (
            <a href={`/batch/${record.id}`} target="_blank" rel="noreferrer">
              {text}
            </a>
          );
        },
        responsive: ["xs", "sm", "md", "lg"],
      },
      {
        title: "Batch Id",
        dataIndex: "id",
        key: "id",
        render: (data) => {
          const copyBatchIDHandler = () => {
            window.navigator.clipboard.writeText(data);
          };

          return (
            <div className="batch_id_text" onClick={copyBatchIDHandler}>
              <Paragraph copyable code>
                {data}
              </Paragraph>
            </div>
          );
        },
        responsive: ["xs", "sm", "md", "lg"],
      },

      {
        title: "Request Source Type",
        dataIndex: "source_type",
        key: "source_type",
        responsive: ["xs", "sm", "md", "lg"],
        render: (text) => (
          <Tag color="cyan" key={text}>
            {text.toUpperCase()}
          </Tag>
        ),
      },
      {
        title: "Status",
        dataIndex: "status_id",
        key: "status_id",
        render: (data) => (
          <Tag color={STATUS_COLOR[data]} key={data}>
            {data}
          </Tag>
        ),
        responsive: ["xs", "sm", "md", "lg"],
      },
      {
        title: "Environment",
        dataIndex: "environmentMode",
        key: "environmentMode",
        render: (data) => (
          <Tag color={STATUS_COLOR[data]} key={data}>
            {data}
          </Tag>
        ),
        responsive: ["xs", "sm", "md", "lg"],
      },
      {
        title: "Execution Mode",
        dataIndex: "executionMode",
        key: "executionMode",
        responsive: ["xs", "sm", "md", "lg"],
      },

      {
        title: "Total Requests",
        dataIndex: "total_requests_count",
        key: "total_requests_count",
        responsive: ["xs", "sm", "md", "lg"],
      },
      {
        title: "Created Requests",
        dataIndex: "request_counter",
        key: "request_counter",
        responsive: ["xs", "sm", "md", "lg"],
      },
      {
        title: "Completed Requests",
        dataIndex: "completed_requests_count",
        key: "completed_requests_count",
        responsive: ["xs", "sm", "md", "lg"],
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => {
          return <p>{getDateTimeString(date)}</p>;
        },
        responsive: ["xs", "sm", "md", "lg"],
        defaultSortOrder: ["descend"],
        sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      },
    ];

    const pauseAction = {
      title: "Action",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            <ActionConfirm
              title="Confirm to Pause"
              batchAction={() => batchActionHandler(text, PAUSE_BATCH)}
              Icon={PauseCircleOutlined}
            />
          </Space>
        );
      },
      width: 90,
    };

    const executeAction = {
      title: "Action",
      key: "actions",
      width: 90,
      render: (text, record) => {
        if (text.execution_mode === 1) {
          return (
            <Space>
              <ActionConfirm
                title="Confirm to Execute"
                Icon={PlusCircleOutlined}
                btnClassName="success-btn"
                batchAction={() => batchActionHandler(text, EXECUTE_BATCH)}
              />
            </Space>
          );
        }
        if (text.execution_mode === 2) {
          return null;
        }
      },
    };

    const resumeAction = {
      title: "Action",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            <ActionConfirm
              title="Confirm to Resume"
              batchAction={() => batchActionHandler(text, RESUME_BATCH)}
              Icon={PlayCircleOutlined}
              btnClassName="success-btn"
            />
          </Space>
        );
      },
      width: 90,
    };

    if (
      userDetails.role === ROLE["STAFF_USER"] ||
      userDetails.role === ROLE["SUPER_USER"]
    ) {
      if (batchName === PAUSED_BATCHES) {
        columnTitle.splice(1, 0, resumeAction);
      } else if (batchName === IN_PROGRESS_BATCHES) {
        columnTitle.splice(1, 0, pauseAction);
      } else if (batchName === PENDING_BATCHES) {
        columnTitle.splice(1, 0, executeAction);
      }
      return columnTitle;
    } else {
      return columnTitle;
    }
  };

  const batchActionHandler = (batchDetail, actionType) => {
    let url = generateApiURL(actionType, batchDetail.id);
    setTableLoading(true);

    if (
      actionType === PAUSE_BATCH &&
      batchDetails.executingBatches.length === 1
    ) {
      setBatchDetails((prevState) => ({
        ...prevState,
        pausedBatches: [
          ...prevState.pendingBatches,
          ...prevState.executingBatches,
        ],
        executingBatches: [],
      }));
    }
    if (
      actionType === RESUME_BATCH &&
      batchDetails.pausedBatches.length === 1
    ) {
      setBatchDetails((prevState) => ({
        ...prevState,
        executingBatches: [
          ...prevState.pausedBatches,
          ...prevState.executingBatches,
        ],
        pausedBatches: [],
      }));
    }

    if (
      actionType === EXECUTE_BATCH &&
      batchDetails.pendingBatches.length === 1
    ) {
      setBatchDetails((prevState) => ({
        ...prevState,
        executingBatches: [
          ...prevState.executingBatches,
          ...prevState.pendingBatches,
        ],
        pendingBatches: [],
      }));
    }

    postAPI(url)
      .then((res) => {
        if (res.response) {
          setRefreshComponent((prevState) => !prevState);
          if (actionType === EXECUTE_BATCH) {
            message.success(`${batchDetail.name} is now running`);
          }

          if (actionType === RESUME_BATCH) {
            message.success("Batch is now resumed");
          }

          if (actionType === PAUSE_BATCH) {
            message.success("Batch is now paused");
          }
        }
      })
      .finally(() => setTableLoading(false));
  };

  const getBatchesByDate = (val) => {
    if (!val) return;
    setStartEndDate(val);
  };

  const getBatchesByDateHandler = () => {
    setDateRangeBtnLoading(true);
    if (!startEndDate.length) {
      message.error("Please select the dates");
      return;
    }

    setResetStartEndDate(true);

    let toDate = new Date(startEndDate[1]._d);
    let toDateString = getDateString(toDate);

    let fromDate = new Date(startEndDate[0]._d);
    let fromDateString = getDateString(fromDate);

    setStartEndDateVal({
      toDate: toDateString,
      fromDate: fromDateString,
    });

    let batch = BATCH_DETAILS.filter(
      (item) => item.key === parseInt(currentActiveTab)
    );
    getBatches(batch[0].batchId, 1, 10, toDateString, fromDateString, true);
    setDateRangeBtnLoading(false);
  };

  return (
    <>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }}>
          <div className={classes.batchContainer}>
            <RBAC allowedRoles={[ROLE["SUPER_USER"], ROLE["STAFF_USER"]]}>
              <CheckFileUploadModal checkNearMap={true} />
            </RBAC>
            <RBAC allowedRoles={[ROLE["SUPER_USER"], ROLE["STAFF_USER"]]}>
              <CheckFileUploadModal />
            </RBAC>
            <RBAC allowedRoles={[ROLE["SUPER_USER"]]}>
              <FixedBatch
                setRefreshComponent={setRefreshComponent}
                featureOptions={orchestratorMetaData.features}
                pipelineOptions={orchestratorMetaData.pipelines}
                wingsSourceTypeOptions={
                  orchestratorMetaData.target_source_types
                }
                localOrchMetadata={localMetaDataOrch}
                teamsList={teams}
              />
              {/* <ScheduledBatch
                setRefreshComponent={setRefreshComponent}
                featureOptions={orchestratorMetaData.features}
                pipelineOptions={orchestratorMetaData.pipelines}
                wingsSourceTypeOptions={
                  orchestratorMetaData.target_source_types
                }
                localOrchMetadata={localMetaDataOrch}
              /> */}
            </RBAC>

            <RBAC allowedRoles={[ROLE["SUPER_USER"], ROLE["STAFF_USER"]]}>
              <FailedRequestsModal />
            </RBAC>

            <RBAC allowedRoles={[ROLE["SUPER_USER"]]}>
              <ConvertGeojsonToCSV />
            </RBAC>

            <Tooltip title="Refresh data">
              <Button
                onClick={getLatestDataFromServer}
                type="primary"
                shape="circle"
                icon={<ReloadOutlined />}
              ></Button>
            </Tooltip>
          </div>
        </Col>

        <div className={classes.createdBatchContainer}>
          <Row>
            <Col span={22} offset={1}>
              <div>
                <Typography.Title level={4}>Pending Batches</Typography.Title>
                {batchDetails.pendingBatches.length !== 0 ? (
                  <Table
                    dataSource={batchDetails.pendingBatches}
                    columns={getColumnTitles("pendingBatches")}
                    bordered
                    scroll={{ x: true }}
                    // tableLayout="fixed"
                    loading={tableLoading}
                  />
                ) : (
                  <NoDataFound subTitle="Currently there are no batches in pending state" />
                )}
              </div>
            </Col>
          </Row>
        </div>

        <div className={classes.requestContainer}>
          <Row>
            <Col span={22} offset={1}>
              <Space style={{ margin: "1rem 0" }}>
                <RangePicker onChange={getBatchesByDate} value={startEndDate} />
                <Button
                  type="primary"
                  onClick={getBatchesByDateHandler}
                  loading={dateRangeBtnLoading}
                >
                  Submit
                </Button>

                {resetStartEndDate && (
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => {
                      setStartEndDateVal({
                        toDate: null,
                        fromDate: null,
                      });
                      setStartEndDate([]);
                      setResetStartEndDate(false);
                      setRefreshComponent((prevState) => !prevState);
                      setDateRangeBtnLoading(false);
                    }}
                    icon={<CloseCircleOutlined />}
                  ></Button>
                )}
              </Space>
              <Tabs
                defaultActiveKey="1"
                type="card"
                size="medium"
                onChange={(activeKey) => setCurrentActiveTab(activeKey)}
              >
                {BATCH_DETAILS.map((item) => (
                  <TabPane tab={item.name} key={item.key}>
                    <div style={{ width: "100%" }}>
                      <Table
                        dataSource={batchDetails[item.batchName]}
                        columns={getColumnTitles(item.batchName)}
                        bordered
                        scroll={{ x: true }}
                        loading={tableLoading}
                        // tableLayout="fixed"
                        pagination={{
                          onChange: (page, pageSize) =>
                            getBatches(
                              item.batchId,
                              page,
                              pageSize,
                              startEndDateVal.toDate,
                              startEndDateVal.fromDatem
                            ),
                          total: totalBatchCount[item.batchName],
                          showSizeChanger: true,
                          onShowSizeChange: (current, size) =>
                            getBatches(item.status, current, size),
                        }}
                      />
                    </div>
                  </TabPane>
                ))}
              </Tabs>
            </Col>
          </Row>
        </div>
      </Row>
    </>
  );
};

export default React.memo(Dashboard);
