import React, { useState } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Alert, Button, message, Modal, Space, Tooltip } from "antd";

import classes from "./Dashboard.module.css";
import { postAPI } from "../Services/Api";
import { CONVERT_GEOJSON_TO_CSV } from "../Constants/URLs";

const ConvertGeojsonToCSV = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [geojsonFile, setGeojsonFile] = useState(null);
  const [outputResults, setOutputResults] = useState({});
  const [showDownloadDetails, setShowDownloadDetails] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    if (!geojsonFile) {
      message.info("Please upload a file");
      setIsLoading(false);
      return;
    }
    let formData = new FormData();
    formData.append("input_file", geojsonFile);

    postAPI(CONVERT_GEOJSON_TO_CSV, formData, { multipartFormData: true })
      .then((response) => {
        if (response.output_file) {
          setOutputResults(response);
          setShowDownloadDetails(true);
          setIsLoading(false);
        } else {
          message.error(
            "There was some error while converting the geojson, please try again."
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const resetData = () => {
    setIsLoading(false);
    setOutputResults({});
    setShowDownloadDetails(false);
    setIsOpen(false);
    setGeojsonFile(null);
  };

  return (
    <>
      <Tooltip title="Convert Geojson to CSV">
        <Button
          type="primary"
          icon={<CloudUploadOutlined />}
          shape="circle"
          className={classes.batchBtn}
          onClick={() => setIsOpen(true)}
        ></Button>
      </Tooltip>

      <Modal
        visible={isOpen}
        title="Convert Geojson to CSV"
        okText="Submit"
        onCancel={() => resetData()}
        footer={[
          <Button type="ghost" onClick={() => resetData()} key={"cancel-btn"}>
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={handleSubmit}
            key={"start-btn"}
            loading={isLoading}
          >
            Submit
          </Button>,
        ]}
      >
        <Space direction="vertical">
          <Alert
            message="Conversion to csv is only supported for orchestrator source type batches."
            type="info"
          />

          <h3>Upload a Geojson file below</h3>
          <input
            type="file"
            accept=".geojson"
            onChange={(e) => setGeojsonFile(e.target.files[0])}
          />

          {showDownloadDetails && (
            <>
              <h3>Your CSV file is now ready to be downloaded!</h3>
              <a href={outputResults?.output_file} download>
                <Button type="primary" className="success-btn">
                  Download
                </Button>
              </a>
            </>
          )}
        </Space>
      </Modal>
    </>
  );
};

export default ConvertGeojsonToCSV;
