import { Switch, Route } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Navbar from "./Components/Navbar/Navbar";
import Login from "./Login/Login";

import Monitor from "./Monitor/Monitor";
import ProtectedRoute from "./Components/ProtectedRoute";
import SearchPage from "./Components/SearchPage";

const App = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <Route path="/" exact>
          <Login />
        </Route>

        <ProtectedRoute component={Dashboard} path="/dashboard" exact />
        <ProtectedRoute component={Monitor} path="/batch/:uniqueId" exact />
        <ProtectedRoute
          component={SearchPage}
          path="/dashboard/results"
          exact
        />
      </Switch>
    </>
  );
};

export default App;
