import { Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const AuthContext = React.createContext();

const AuthCheck = ({ children }) => {
  const history = useHistory();
  const [token, setToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userDetails, setUserDetails] = useState({
    id: "",
    role: "",
    email: "",
  });

  useEffect(() => {
    let userLocal = JSON.parse(localStorage.getItem("user"));
    if (userLocal?.id && localStorage.getItem("auth")) {
      setToken(localStorage.getItem("auth"));
      setUserDetails({
        id: userLocal.id,
        role: userLocal.role,
        email: userLocal.email,
      });
      setIsAuthenticated(true);
      setIsLoaded(true);
    } else {
      setIsAuthenticated(false);
      setIsLoaded(true);
      setToken("");
      setUserDetails({
        id: "",
        role: "",
        email: "",
      });
    }
  }, []);

  const logoutHandler = () => {
    setIsAuthenticated(false);
    setToken("");
    setUserDetails({
      id: "",
      role: "",
      email: "",
    });
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    history.push("/");
  };

  return (
    <>
      {!isLoaded ? (
        <div
          style={{ display: "grid", placeContent: "center", height: "100vh" }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <AuthContext.Provider
          value={{
            token,
            setToken,
            isAuthenticated,
            setIsAuthenticated,
            logoutHandler,
            setUserDetails,
            userDetails,
          }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </>
  );
};

export default AuthCheck;
