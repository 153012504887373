import { Button, Input, Modal, Space } from "antd";
import React, { useState } from "react";
import {
  DownCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import classes from "./Dashboard.module.css";
import { postAPI } from "../Services/Api";
import { CREATE_REQUESTS_BATCH_FILE } from "../Constants/URLs";
import { REQUESTS_STATUS } from "../Constants";

const FailedRequestsModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [batchIds, setBatchIds] = useState([{ id: "" }]);
  const [showDownloadDetails, setShowDownloadDetails] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [okBtnLoading, setOkBtnLoading] = useState(false);

  const handleInputChange = (value, index) => {
    let batches = [...batchIds];
    batches[index].id = value;
    setBatchIds([...batches]);
  };

  const inputDeleteHandler = (index) => {
    let batches = [...batchIds];
    batches.splice(index, 1);
    setBatchIds([...batches]);
  };

  const addInputUI = () =>
    batchIds.map((item, index) => {
      return (
        <div key={index} style={{ display: "flex", flexWrap: "wrap" }}>
          <Input
            placeholder="Add Batch ID"
            value={item.id}
            onChange={(event) => handleInputChange(event.target.value, index)}
            style={{ marginBottom: "1rem", width: "85%" }}
            key={index}
          />
          {index !== 0 ? (
            <Button
              style={{ margin: "0 0.5rem" }}
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => inputDeleteHandler(index)}
            ></Button>
          ) : null}
        </div>
      );
    });

  const handleSubmit = () => {
    setOkBtnLoading(true);
    let batches = [];
    batchIds.map((item) => (batches = [...batches, item.id]));

    let payload = {
      batch_ids: batches,
      status: [REQUESTS_STATUS.FAILED],
    };
    postAPI(CREATE_REQUESTS_BATCH_FILE, payload, {})
      .then((res) => {
        if (res.output_file) {
          setDownloadLink(res.output_file);
          setShowDownloadDetails(true);
        }
      })
      .finally(() => setOkBtnLoading(false));
  };

  const resetData = () => {
    setIsOpen(false);
    setShowDownloadDetails(false);
    setDownloadLink("");
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setIsOpen(true)}
        className={classes.batchBtn}
        icon={<DownCircleOutlined />}
      >
        Failed Requests
      </Button>
      <Modal
        visible={isOpen}
        title="Add Batch IDs"
        onCancel={() => resetData()}
        footer={[
          <Button type="ghost" onClick={() => resetData()} key={"cancel-btn"}>
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={handleSubmit}
            key={"start-btn"}
            loading={okBtnLoading}
          >
            Submit
          </Button>,
        ]}
      >
        <section>{addInputUI()}</section>
        <Space direction="vertical">
          <Button
            type="primary"
            onClick={() =>
              setBatchIds((prevState) => [...prevState, { id: "" }])
            }
            icon={<PlusCircleOutlined />}
          >
            Add
          </Button>
          {showDownloadDetails && (
            <>
              <a href={downloadLink} download="output.json">
                <Button type="primary" className="success-btn">
                  Download
                </Button>
              </a>
            </>
          )}
        </Space>
      </Modal>
    </>
  );
};

export default React.memo(FailedRequestsModal);
