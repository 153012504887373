import { useContext } from "react";
import { AuthContext } from "../../Stores/AuthContext";

const RBAC = ({ allowedRoles, children }) => {
  // let { role: userRole } = JSON.parse(localStorage.getItem("user"));
  const { userDetails } = useContext(AuthContext);

  return allowedRoles.includes(userDetails.role) && children;
};

export default RBAC;
