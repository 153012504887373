import { Button, Modal, message, Upload, Typography, Alert } from "antd";
import React, { useState, useEffect } from "react";
import {
  InboxOutlined,
  FileZipFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { postAPI, interpolate } from "../Services/Api";

import { SINGLE_FILE_UPLOAD } from "../Constants/URLs";

import FileUploadLine from "../Assets/FileUploadLine.svg";
import { formatCurrentDate } from "../Utils/HelperFunctions";
import classes from "../Dashboard/Dashboard.module.css";

const FileUploadModal = ({
  isOpen,
  setIsOpen,
  requestId,
  setIsUploadingArray,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadedFile, setuploadedFile] = useState({});
  const resetData = () => {
    setIsOpen(false);
    setIsUploaded(false);
    setuploadedFile({});
  };
  const handleDelete = () => {
    setuploadedFile({});
    setIsUploaded(false);
    message.error("File removed");
  };
  const { Dragger } = Upload;
  const props = {
    accept: ".zip",
    beforeUpload: (file) => {
      setIsOpen(false);
      setIsUploaded(true);
      setuploadedFile(file);
      return false;
    },
    showUploadList: false,
  };
  const handleSubmit = async () => {
    try {
      setIsUploaded(false);
      setIsUploading(true);
      setIsUploadingArray((prev) => [...prev, requestId]);
      let formData = new FormData();
      formData.append("input_file", uploadedFile);
      const url = interpolate(SINGLE_FILE_UPLOAD, [requestId]);
      const res = await postAPI(url, formData);
      if (res.status === 200 || res.status === 202) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
      setIsUploading(false);
      setIsUploadingArray((prev) => prev.filter((item) => item !== requestId));
    } catch (err) {
      console.log(err);
      setIsUploading(false);
      setIsUploadingArray((prev) => prev.filter((item) => item !== requestId));
    }
  };
  return (
    <>
      <Modal
        visible={isOpen}
        title="Upload file"
        onCancel={() => resetData()}
        footer={[]}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      </Modal>
      <Modal
        visible={isUploaded}
        title="Upload file"
        onCancel={() => resetData()}
        footer={[]}
      >
        <div className={classes.cardRowContainer}>
          <div className={classes.cardInfoContainer}>
            <FileZipFilled className={classes.fileIcon} />
            <div className={classes.fileDetailContainer}>
              <Typography className={classes.fileUpperDetail}>
                {uploadedFile.name}
              </Typography>
              <div style={{ display: "flex" }}>
                <Typography className={classes.fileLowerDetail}>
                  {formatCurrentDate()}
                </Typography>
                <Typography className={classes.fileLowerDetail}>
                  <span style={{ fontWeight: "bold", paddingLeft: "4px" }}>
                    ·
                  </span>{" "}
                  {Math.floor(uploadedFile.size / 1000)} Kb
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.closeCircleOutlinedContainer}>
            <CloseCircleOutlined
              style={{ fontSize: "1.5rem" }}
              onClick={() => handleDelete()}
            />
          </div>
        </div>
        <div className={classes.uploadBtnContainer}>
          <Button type="primary" onClick={() => handleSubmit()}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(FileUploadModal);
