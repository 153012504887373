import React, { useContext, useState } from "react";
import { Row, Col, Button, Input, message } from "antd";
import classes from "./Login.module.css";

import { Redirect } from "react-router-dom";
import { AuthContext } from "../Stores/AuthContext";
import { loginAPI } from "../Services/Api";
import { LOGIN } from "../Constants/URLs";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { setIsAuthenticated, isAuthenticated, setToken, setUserDetails } =
    useContext(AuthContext);

  const validateEmail = (emailId) => {
    let reg = /\S+@\S+\.\S+/;
    return reg.test(emailId);
  };

  const submitHandler = () => {
    setIsLoading(true);
    if (email === "") {
      message.error("Please enter email");
      return;
    }
    if (password === "") {
      message.error("Please enter password");
      return;
    }

    if (!validateEmail(email)) {
      message.error("Please Enter correct Email ID");
      return;
    }

    let payload = { email, password };
    let config = { loginAPI: true };
    loginAPI(LOGIN, payload, config)
      .then((res) => {
        let userData = {
          id: res.id,
          role: res.role,
          email: res.email,
        };
        localStorage.setItem("auth", res.token);
        localStorage.setItem("user", JSON.stringify(userData));
        setIsLoading(false);
        setToken(res.token);
        setUserDetails(userData);
        setIsAuthenticated(true);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard/" exact />;
  }

  return (
    <>
      <Row>
        <Col
          xs={{ span: 20, offset: 2 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 6, offset: 9 }}
        >
          <div className={classes.loginContainer}>
            <h1 className={classes.loginTitle}>Welcome to Flock</h1>

            <div className={classes.loginInputContainer}>
              <Input
                type="email"
                placeholder="Enter your email...."
                className={classes.loginInput}
                required
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <Input.Password
                placeholder="Enter your password...."
                required
                type="password"
                className={classes.loginInput}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="primary"
                className={classes.loginBtn}
                onClick={submitHandler}
                loading={isLoading}
              >
                Login
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Login);
