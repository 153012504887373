import { message } from "antd";
import axios from "axios";
import ErrorMessage from "../Components/ErrorMessage";
import { getAuthToken } from "../Utils/HelperFunctions";

let BASE_SERVER_URL;
if (process.env.REACT_APP_ENV === "stage") {
  BASE_SERVER_URL = "https://staging.flock-back.attentive.ai/api/";
} else if (process.env.REACT_APP_ENV === "prod") {
  BASE_SERVER_URL = "https://flock-back.attentive.ai/api/";
} else {
  BASE_SERVER_URL = "https://staging.flock-back.attentive.ai/api/";
}

export const request = axios.create({
  baseURL: BASE_SERVER_URL,
});

const handleLogout = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("user");
  window.location.href = "/";
};

const handleError = (error) => {
  if (error.message === "Network Error") {
    message.error("Network error, not able to connect to server");
    return;
  }
  if (error.response.status === 404) {
    message.error(<ErrorMessage message={"Resource not found"} />, 1);
  } else if (error.response.status === 401) {
    message.error(<ErrorMessage message={error.response.data.error_type} />, 2);
    handleLogout();
  } else if (error.response.status === 500) {
    message.error("Internal Server Error");
  } else {
    error.response.data.errors.forEach((item, index) =>
      message.error(<ErrorMessage key={index} message={item.message} />)
    );
  }
};

/**
 *
 * @param {*} endpoint : string, for ex: /api/batch/
 * @param {*} paramData : Any parameter that has to be passed in this GET call.
 *
 * @returns a javascript promise ( can be used in async/await fashion or .then().catch() fashion )
 */

export const getAPI = (endpoint, paramData = {}) => {
  return new Promise((resolve, reject) => {
    request
      .get(endpoint, {
        params: paramData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${getAuthToken()}`,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => {
        handleError(err);
        reject(err);
      });
  });
};

/**
 * @description This is the extension/custom function which makes a POST call to the backend built on top of axios.
 *
 * @param {string} endpoint : string, for ex: /api/batch/
 * @param {object} payload : Javascript Object/ formdata of the data that has to be sent to backend.
 * @param {object} config : any other configuration for the api call.
 *
 * @returns a javascript promise.
 */

export const postAPI = (endpoint, payload, config = {}) => {
  // add a conf=dition in headers and passs json everywhere
  let options = {};
  if (config?.multipartFormData) {
    options = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${getAuthToken()}`,
      },
    };
  } else if (config?.loginAPI) {
    options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  } else {
    options = {
      headers: {
        Authorization: `Token ${getAuthToken()}`,
      },
    };
  }

  return new Promise((resolve, reject) => {
    request
      .post(endpoint, payload, { ...options })
      .then((response) => resolve(response.data))
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
};

export const patchAPI = (endpoint, payload, config = {}) => {
  let options = {};
  if (config?.multipartFormData) {
    options = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${getAuthToken()}`,
      },
    };
  } else {
    options = {
      headers: {
        Authorization: `Token ${getAuthToken()}`,
      },
    };
  }

  return new Promise((resolve, reject) => {
    request
      .patch(endpoint, payload, { ...options })
      .then((res) => resolve(res.data))
      .catch((err) => {
        handleError(err);
        reject(err);
      });
  });
};

export const loginAPI = (endpoint, payload, config = {}) => {
  let options = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return new Promise((resolve, reject) => {
    request
      .post(endpoint, payload, { ...options })
      .then((response) => resolve(response.data))
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
};

export const interpolate = function (theString, argumentArray) {
  var regex = /%s/;
  var _r = function (p, c) {
    return p.replace(regex, c);
  };
  return argumentArray.reduce(_r, theString);
};
