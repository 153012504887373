import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Table,
  Button,
  Input,
  message,
  Tag,
  Typography,
  Descriptions,
  Divider,
  Space,
  Tooltip,
  Select,
  Dropdown,
  Menu,
  Skeleton,
  DatePicker,
  Checkbox,
  Spin,
} from "antd";

import moment from "moment";
import { useParams } from "react-router-dom";
import {
  ReloadOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  PaperClipOutlined,
  SearchOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { GET_EXECUTION_MODE, GET_STATUS } from "../Utils/Utils";
import { DownloadOutlined } from "@ant-design/icons";
import FileDownloadLine from "../Assets/download-line.svg";
import FileUploadLine from "../Assets/upload-line.svg";
import CreateReq from "../Assets/create_req.svg";
import InProgReq from "../Assets/inprog-req.svg";
import FailedReq from "../Assets/failed-req.svg";
import CompletedReq from "../Assets/completed-req.svg";
import FileUploadModal from "./FileUploadModal";
import FileTextLine from "../Assets/file-text-line.svg";
import {
  GET_ORCHESTRATOR_METADATA,
  SINGLE_FILE_DOWNLOAD,
  TEAMS_NAMES,
} from "../Constants/URLs";
import {
  downloadSingleFile,
  downloadImage,
  getENV,
} from "../Utils/HelperFunctions";
import {
  getDateTimeString,
  getTimeInHoursAndMins,
  showTitle,
} from "../Utils/HelperFunctions";
import {
  BATCH_STATUS,
  CSV,
  EXECUTION_MODE,
  ORCHESTRATOR,
  REQUESTS_STATUS,
  STATUS_COLOR,
  WINGS,
  JSON_,
  REQUEST_STATUS_TEXT,
  PENDING,
  IN_PROGRESS_,
  QR_STATUS,
  QR_STATUS_ENUMS,
  FILTER,
  PAUSED,
  ALL,
  TO_BE_DONE,
} from "../Constants";
import Card from "../Components/Card";
import { getAPI, interpolate, postAPI } from "../Services/Api";
import {
  CREATE_REQUESTS_BATCH_FILE,
  generateApiURL,
  LIST_ALL_BATCHES,
  PAUSE_BATCH,
  RESUME_BATCH,
  UPDATE_REQUEST_META_DATA,
  BULK_DOWNLOAD,
  BULK_RERUN_REQUESTS,
} from "../Constants/URLs";
import EditSettings from "./EditSettings";
import RBAC from "../Components/HOC/RBAC";
import { ROLE } from "../Constants/Roles";
import QRComments from "../Components/QRComments";
import FixedBatch from "../Components/Fixedbatch/FixedBatch";
import CopyText from "../Components/CopyText";

const { Paragraph } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Monitor = () => {
  const [batchDetails, setBatchDetails] = React.useState({
    name: "",
    id: "",
    execution_mode: "",
    environment: "",
    input_format: "",
    total_requests_count: "",
    source_type: "",
    status: "",
    meta_data: { fms: false, features: {} },
    rerun_count: 0,
  });
  // default table data
  // const [requestDetails, setRequestDetails] = useState([]);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [isSearched, setIsSearched] = useState(false);

  // Implemented only for FIXED batch as of now, due to a bug in scheduled batch.
  const [maxRequests, setMaxRequests] = useState("");
  const [notificationPercentage, setNotificationPercentage] = useState("");
  const [filteredValue, setFilteredValue] = useState(null);
  const [filteredDate, setFilteredDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [requestCount, setRequestCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [requestsBtnLoading, setRequestBtnLoading] = useState(false);
  const [batchFileBtnLoading, setBatchFileBtnLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [qrFilter, setQRFilter] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRequestID, setSelectedRequestID] = useState(null);
  const [isRerun, setIsRerun] = useState(false);
  const [isUploadingArray, setIsUploadingArray] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const [orchestratorMetaData, setOrchestratorMetaData] = useState({
    features: [],
    pipelines: [],
    target_source_types: [],
  });

  const [localMetaDataOrch, setLocalMetaDataOrch] = useState({
    features: [],
    pipeline: "",
    target_source_type: "",
  });
  const [teams, setTeams] = useState([]);

  let { uniqueId } = useParams();

  const setDataInterval = () => {
    let id = setInterval(() => {
      getLatestDataFromServer();
    }, 10000);

    return id;
  };
  const handleCheckboxClick = (e) => {
    const newSelectedRequests = [...selectedRequests];
    if (e.target.checked) {
      newSelectedRequests.push(e.target.value);
    } else {
      newSelectedRequests.splice(
        newSelectedRequests.indexOf(e.target.value),
        1
      );
    }
    setSelectedRequests(newSelectedRequests);
    if (newSelectedRequests.length) setIsVisible(true);
    else setIsVisible(false);
  };

  const handleClickDownload = async (reqid) => {
    try {
      const url = interpolate(SINGLE_FILE_DOWNLOAD, [reqid]);
      const res = await getAPI(url);
      await downloadSingleFile(res);
    } catch (err) {
      console.log(err);
      message.error("Error downloading file");
    }
  };

  const getColumnTitle = () => {
    const requestColumns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (data) => {
          const handleUploadClick = () => {
            setSelectedRequestID(data);
            setIsOpenModal(true);
          };

          return (
            <div className="container-gap">
              <Checkbox
                value={data}
                checked={selectedRequests.includes(data)}
                onClick={handleCheckboxClick}
              />
              <div className="flex-wrap">
                <span style={{ width: "100%" }}>
                  <CopyText text={data} />
                </span>

                <div style={{ display: "flex" }}>
                  {isUploadingArray.includes(data) ? (
                    <Spin size="small" />
                  ) : (
                    <img
                      src={FileUploadLine}
                      onClick={handleUploadClick}
                      className="cursor-pointer"
                    />
                  )}

                  <img
                    className="ml-3 cursor-pointer"
                    src={FileDownloadLine}
                    onClick={() => handleClickDownload(data)}
                  />
                </div>
              </div>
            </div>
          );
        },
        responsive: ["xs", "sm", "md", "lg"],
      },

      {
        title: "Source Type Request ID",
        dataIndex: "source_type_req_id",
        key: "source_type_req_id",
        render: (data) => {
          return (
            <span className="batch_id_text">
              <CopyText text={data} />
            </span>
          );
        },
        responsive: ["xs", "sm", "md", "lg"],
      },

      {
        title: "Status",
        dataIndex: "statusResult",
        key: "statusResult",
        render: (data) => {
          return (
            <Tag color={STATUS_COLOR[data]} key={data}>
              {data}
            </Tag>
          );
        },
        responsive: ["xs", "sm", "md", "lg"],
      },

      {
        title: "Created At",
        dataIndex: "created_time",
        key: "created_time",
        responsive: ["xs", "sm", "md", "lg"],
        defaultSortOrder: "ascend",
        render: (date) => {
          return <p>{getDateTimeString(date)}</p>;
        },
        sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      },
    ];

    const fmsColumnDetails = [
      {
        title: "Old Request ID",
        dataIndex: "old_request_id",
        key: "old_request_id",
        render: (data) => {
          return (
            <>
              {data !== "-" ? (
                <span className="batch_id_text">
                  <CopyText text={data} />
                </span>
              ) : (
                <p>{data}</p>
              )}
            </>
          );
        },
        responsive: ["xs", "sm", "md", "lg"],
      },
      {
        title: "Shareable Link",
        dataIndex: "shareable_link",
        key: "shareable_link",
        render: (text, record) => {
          return (
            <a href={text} target="_blank" rel="noreferrer">
              <span style={{ color: "#212121" }}>Link</span>
              <PaperClipOutlined className="ml-2" />
            </a>
          );
        },
        responsive: ["xs", "sm", "md", "lg"],
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        responsive: ["xs", "sm", "md", "lg"],
        render: (data) => {
          return <p>{data}</p>;
        },
      },
      {
        title: "Modified At",
        dataIndex: "modified_at",
        key: "modified_at",
        responsive: ["xs", "sm", "md", "lg"],
        render: (date) => {
          return <p>{getDateTimeString(date)}</p>;
        },
        sorter: (a, b) => a.modified_at.localeCompare(b.modified_at),
      },
      {
        title: "QR Status",
        dataIndex: "qr",
        key: "qr",
        responsive: ["xs", "sm", "md", "lg"],
        render: (data, { id, status, meta_data }) => {
          let comment = meta_data?.comment ?? "";
          let selectComponent = null;
          if (status === REQUESTS_STATUS.COMPLETED) {
            selectComponent = (
              <>
                <Select
                  className={`custom-select ${QR_STATUS[data]}`}
                  defaultValue={QR_STATUS[data]}
                  onChange={(value) => updateQR(id, value)}
                  value={data ? QR_STATUS[data] : QR_STATUS[TO_BE_DONE]}
                >
                  {Object.values(QR_STATUS_ENUMS).map((value) => {
                    return (
                      <Option key={value} value={value}>
                        {QR_STATUS[value]}
                      </Option>
                    );
                  })}
                </Select>
              </>
            );
          }

          return selectComponent;
        },
      },
    ];

    return batchDetails.meta_data?.fms
      ? [...requestColumns, ...fmsColumnDetails]
      : requestColumns;
  };

  const SingleBatchDetails = () => {
    getAPI(`${LIST_ALL_BATCHES}${uniqueId}`).then((res) => {
      let response = { ...res };

      if (response.execution_mode === EXECUTION_MODE.FIXED) {
        setMaxRequests(response.config.max_requests);
      }
      setBatchDetails(response);
      setNotificationPercentage(response?.meta_data?.alerts?.percentage);
      setIsLoading(false);
    });
  };

  const getAllRequests = () => {
    setTableLoading(true);
    let params = {
      page: pageDetails.pageNumber,
      page_size: pageDetails.pageSize,
      status: filteredValue,
      field: searchData !== "" ? searchData : undefined,
      qr: qrFilter,
      start_datetime: filteredDate ? filteredDate[0] : undefined,
      end_datetime: filteredDate ? filteredDate[1] : undefined,
    };
    getAPI(`${LIST_ALL_BATCHES}${uniqueId}/requests`, params)
      .then((res) => {
        let response = [...res.results];
        setRequestCount(res.count);

        let result = response.map((item) => {
          const properties =
            item.input_data?.boundary_geojson?.features[0]?.properties ??
            item.input_data?.payload?.input?.boundary_geojson?.features[0]
              ?.properties;

          let shareable_link = item.input_data?.shareable_link ?? "-";

          let old_request_id = properties?.old_request_id ?? "-";

          let address = properties?.address ?? properties?.Address ?? "-";

          let qr = item?.meta_data?.qr;
          if (item.status === REQUESTS_STATUS.COMPLETED && !qr) {
            qr = QR_STATUS_ENUMS.TO_BE_DONE;
          }

          return {
            ...item,
            key: item.id,
            statusResult: GET_STATUS[item.status],
            created_time: `${item["created_at"].split("T")[0]}  , ${
              item["created_at"].split("T")[1].split(".")[0]
            }`,
            modified_at: `${item["modified_at"].split("T")[0]}  , ${
              item["modified_at"].split("T")[1].split(".")[0]
            }`,
            shareable_link,
            old_request_id,
            address,
            qr,
          };
        });
        setFilteredData(result);
        setTableLoading(false);
      })
      .catch((err) => {
        setTableLoading(false);
      });
  };

  useEffect(() => {
    getLatestDataFromServer();
    let intervalID = setDataInterval();

    return () => {
      clearInterval(intervalID);
    };
    // eslint-disable-next-line
  }, [refreshData, pageDetails]);

  const getLatestDataFromServer = () => {
    SingleBatchDetails();
    getAllRequests();
  };

  const filterData = (filterVal, filterBy = FILTER.STATUS) => {
    setPageDetails((prevState) => ({ ...prevState, pageNumber: 1 }));
    if (filterBy === FILTER.STATUS) {
      setFilteredValue(filterVal);
    } else if (filterBy === FILTER.QR) {
      setQRFilter(filterVal);
    } else if (filterBy === FILTER.DATE) {
      setFilteredDate(filterVal);
    }
    setRefreshData((prevState) => !prevState);
  };
  const handleDateChange = (date, dateString) => {
    filterData(dateString, FILTER.DATE);
  };

  const handleBulkDownload = async () => {
    try {
      const url = BULK_DOWNLOAD;
      const payload = {
        batch_id: batchDetails.id,
        req_id_list: selectedRequests,
      };
      const res = await postAPI(url, payload);
      console.log(res);
      message.success(res.message);
    } catch (err) {}
  };

  const handleBulkRerun = async () => {
    try {
      const url = BULK_RERUN_REQUESTS;
      const payload = {
        batch_id: batchDetails.id,
        request_ids: selectedRequests,
      };
      const res = await postAPI(url, payload);
      setBatchDetails((prevState) => ({
        ...prevState,
        rerun_count: res.rerun_count,
      }));
      setLocalMetaDataOrch({
        features: Object.keys(batchDetails?.meta_data?.features),
        pipeline: batchDetails?.meta_data?.pipeline,
        target_source_type: batchDetails?.meta_data?.target_source_type,
      });
      setIsRerun(true);
    } catch (err) {}
  };
  const searchRequest = (event) => {
    // When user presses enter key
    if (event.keyCode === 13) {
      setIsSearched(true);
      setRefreshData((prevState) => !prevState);
    }
  };

  const batchActionHandler = (actionType) => {
    let url = generateApiURL(actionType, batchDetails.id);
    postAPI(url, {}).then((res) => {
      if (res.response) {
        if (actionType === RESUME_BATCH) {
          message.success(`Batch is running!`);
        } else if (actionType === PAUSE_BATCH) {
          message.success(`Batch is paused!`);
        }
        window.location.reload();
      }
    });
  };

  const handleDropdownClick = (e, data_dump = false) => {
    if (data_dump) {
      setRequestBtnLoading(true);
    } else {
      setBatchFileBtnLoading(true);
    }

    let status =
      parseInt(e.key) === REQUESTS_STATUS.ALL
        ? [
            REQUESTS_STATUS.IN_PROGRESS,
            REQUESTS_STATUS.COMPLETED,
            REQUESTS_STATUS.FAILED,
          ]
        : [parseInt(e.key)];
    let payload = {
      batch_ids: [batchDetails.id],
      status,
      data_dump,
      offset: new Date().getTimezoneOffset(),
    };

    let extensionType = batchDetails.source_type === WINGS ? JSON_ : CSV;
    const getFileName = () => {
      let name = "";
      switch (parseInt(e.key)) {
        case REQUESTS_STATUS.IN_PROGRESS:
          name = `${batchDetails.name}-In_Progress.${extensionType}`;
          break;
        case REQUESTS_STATUS.FAILED:
          name = `${batchDetails.name}-Failed.${extensionType}`;
          break;
        case REQUESTS_STATUS.COMPLETED:
          name = `${batchDetails.name}-Completed.${extensionType}`;
          break;
        default:
          name = `${batchDetails.name}-all.${extensionType}`;
      }
      return name;
    };

    postAPI(CREATE_REQUESTS_BATCH_FILE, payload)
      .then((res) => {
        let fileName = getFileName();
        const aTag = document.createElement("a");
        aTag.href = res.output_file;
        aTag.download = `${fileName}`;
        aTag.click();
        aTag.remove();
      })
      .finally(() => {
        if (data_dump) {
          setRequestBtnLoading(false);
        } else {
          setBatchFileBtnLoading(false);
        }
      });
  };
  const fetchMetaData = () => {
    getAPI(GET_ORCHESTRATOR_METADATA).then((res) => {
      setOrchestratorMetaData({
        features: res.features,
        pipelines: res.pipelines,
        target_source_types: res.target_source_types,
      });
      setLocalMetaDataOrch({
        features: Object.keys(batchDetails?.meta_data?.features),
        pipeline: batchDetails?.meta_data?.pipeline,
        target_source_type: batchDetails?.meta_data?.target_source_type,
      });
    });
  };

  const fetchTeamsList = () => {
    const env = getENV();
    postAPI(TEAMS_NAMES, { environment: env }).then((res) => {
      setTeams([...res]);
    });
  };
  const displayDropdownMenu = (batchFile = false) => {
    const handleOnClick = (event) => {
      if (batchFile) {
        handleDropdownClick(event);
      } else {
        handleDropdownClick(event, true);
      }
    };

    const menuContent = Object.keys(REQUESTS_STATUS)
      .filter((item) => item !== PENDING && item !== IN_PROGRESS_)
      .map((item) => {
        return (
          <Menu.Item
            key={REQUESTS_STATUS[item]}
            onClick={(e) => handleOnClick(e)}
          >
            {REQUEST_STATUS_TEXT[item]}
          </Menu.Item>
        );
      });

    return <Menu>{menuContent}</Menu>;
  };

  const updateQR = (request_id, value = 0, comment = "", callbackFunc) => {
    setTableLoading(true);
    let payload = {
      request_id,
      ...(parseInt(value) && { qr: parseInt(value) }),
      ...(comment && { comment }),
    };
    postAPI(UPDATE_REQUEST_META_DATA, payload)
      .then((res) => {
        message.success(res.message);
        setRefreshData((prevState) => !prevState);
        callbackFunc && callbackFunc();
      })
      .finally(() => setTableLoading(false));
  };
  useEffect(() => {
    if (selectedRequests.length) {
      setIsVisible(true);
    }
  }, [selectedRequests]);
  useEffect(() => {
    fetchMetaData();
    fetchTeamsList();
  }, []);
  return (
    <>
      <FixedBatch
        batchDetails={batchDetails}
        maxRequests={maxRequests}
        isRerun={isRerun}
        setIsRerun={setIsRerun}
        showButton={false}
        featureOptions={orchestratorMetaData.features}
        pipelineOptions={orchestratorMetaData.pipelines}
        wingsSourceTypeOptions={orchestratorMetaData.target_source_types}
        localOrchMetadata={localMetaDataOrch}
        teamsList={teams}
        requestIds={selectedRequests}
      />

      <FileUploadModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        requestId={selectedRequestID}
        setIsUploadingArray={setIsUploadingArray}
      />
      <div>
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            className="monitorCol"
          >
            <div className="card-container">
              <div>
                <Typography.Title level={5} className="monitorTitle">
                  {batchDetails.name}
                </Typography.Title>
              </div>

              <div
                style={{
                  borderLeft: "1px solid #e8e8e8",
                  height: "100%",
                }}
              ></div>
              <Tag
                color={STATUS_COLOR[GET_STATUS[batchDetails.status]]}
                key={GET_STATUS[batchDetails.status]}
              >
                {GET_STATUS[batchDetails.status]}
              </Tag>
            </div>

            <div className="side-tool-container ">
              {batchDetails.execution_mode === EXECUTION_MODE.FIXED &&
              (batchDetails.status === BATCH_STATUS["IN PROGRESS"] ||
                batchDetails.status === BATCH_STATUS.PAUSED) ? (
                <>
                  <RBAC allowedRoles={[ROLE["SUPER_USER"]]}>
                    <EditSettings
                      batchDetails={batchDetails}
                      maxRequests={maxRequests}
                      setMaxRequests={setMaxRequests}
                      notificationPercentage={notificationPercentage}
                      setNotificationPercentage={setNotificationPercentage}
                    />
                  </RBAC>
                </>
              ) : null}
              <Tooltip title="Download batch file">
                <a
                  download
                  href={batchDetails.batch_file}
                  alt="file-download"
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginTop: "3px" }}
                >
                  <DownloadOutlined
                    style={{ color: "#4D4D4D", fontSize: "18px" }}
                  />
                </a>
              </Tooltip>
              {batchDetails.status === BATCH_STATUS["IN PROGRESS"] && (
                <Tooltip title="Pause this batch">
                  <RBAC allowedRoles={[ROLE["SUPER_USER"], ROLE["STAFF_USER"]]}>
                    <PauseCircleOutlined
                      onClick={() => batchActionHandler(PAUSE_BATCH)}
                      style={{ color: "#4D4D4D", fontSize: "18px" }}
                    />
                  </RBAC>
                </Tooltip>
              )}
              {batchDetails.status === BATCH_STATUS.PAUSED && (
                <Tooltip title="Resume this batch">
                  <RBAC allowedRoles={[ROLE["SUPER_USER"], ROLE["STAFF_USER"]]}>
                    <PlayCircleOutlined
                      onClick={() => batchActionHandler(RESUME_BATCH)}
                      style={{ color: "#4D4D4D", fontSize: "18px" }}
                    />
                  </RBAC>
                </Tooltip>
              )}
              <Tooltip title="Refresh data">
                <ReloadOutlined
                  onClick={getLatestDataFromServer}
                  style={{ color: "#4D4D4D", fontSize: "18px" }}
                />
              </Tooltip>
            </div>
          </Col>
        </Row>

        <div
          style={{
            marginLeft: "4.5rem",
            display: "flex",
            flexDirection: "row",
            gap: "23px",
            flexWrap: "wrap",
            marginTop: "1rem",
          }}
        >
          <div>
            <Skeleton active loading={isLoading}>
              <div className="card-style">
                {" "}
                <div className="row-flex">
                  <div>
                    <img src={CreateReq} />
                  </div>
                  <div className="tile-container">
                    <div>{showTitle("Created requests")}</div>
                    <div className="horizontal-line"></div>
                    <div className="row-flex-gap">
                      <div>
                        <Typography.Title level={5}>
                          {batchDetails.request_counter}
                        </Typography.Title>
                      </div>
                      <div>
                        <Tag color="blue">
                          {parseFloat(
                            batchDetails.created_requests_percentage
                          ).toFixed(2)}
                          {" %"}
                        </Tag>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Skeleton>
          </div>
          <div>
            <Skeleton active loading={isLoading}>
              <div className="card-style">
                <div className="row-flex">
                  <div>
                    <img src={CompletedReq} />
                  </div>
                  <div className="tile-container">
                    <div>{showTitle("Completed requests")}</div>
                    <div className="horizontal-line"></div>
                    <div className="row-flex-gap">
                      <div>
                        <Typography.Title level={5}>
                          {batchDetails.completed_requests_count}
                        </Typography.Title>
                      </div>
                      <div>
                        <Tag color="green">
                          {parseFloat(
                            batchDetails.completed_requests_percentage
                          ).toFixed(2)}
                          {" %"}
                        </Tag>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Skeleton>
          </div>
          <div>
            <Skeleton active loading={isLoading}>
              <div className="card-style">
                {" "}
                <div className="row-flex">
                  <div>
                    <img src={FailedReq} />
                  </div>
                  <div className="tile-container">
                    <div>{showTitle("Failed requests")}</div>
                    <div className="horizontal-line"></div>
                    <div className="row-flex-gap">
                      <div>
                        <Typography.Title level={5}>
                          {batchDetails.failed_requests_count}
                        </Typography.Title>
                      </div>
                      <div>
                        <Tag color="red">
                          {parseFloat(
                            batchDetails.failed_requests_percentage
                          ).toFixed(2)}
                          {" %"}
                        </Tag>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Skeleton>
          </div>
          <div>
            <Skeleton active loading={isLoading}>
              <div className="card-style">
                {" "}
                <div className="row-flex">
                  <div>
                    <img src={InProgReq} />
                  </div>
                  <div className="tile-container">
                    <div>{showTitle("In progress requests")}</div>
                    <div className="horizontal-line"></div>
                    <div className="row-flex-gap">
                      <div>
                        <Typography.Title level={5}>
                          {batchDetails.inprogress_requests_count}
                        </Typography.Title>
                      </div>
                      <div>
                        <Tag color="gold">
                          {parseFloat(
                            batchDetails.inprogress_requests_percentage
                          ).toFixed(2)}
                          {" %"}
                        </Tag>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Skeleton>
          </div>
        </div>

        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            className="mt"
          >
            <Skeleton active loading={isLoading}>
              <Descriptions
                bordered
                column={{ xxl: 3, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}
                contentStyle={{ width: "15vw" }}
                labelStyle={{ fontSize: "1rem", fontWeight: "600" }}
              >
                <Descriptions.Item label="Total requests">
                  {batchDetails.meta_data.total_requests_count}
                </Descriptions.Item>
                <Descriptions.Item label="Request source type">
                  {batchDetails.source_type}
                </Descriptions.Item>
                <Descriptions.Item label="Execution mode">
                  {GET_EXECUTION_MODE[batchDetails.execution_mode]}
                </Descriptions.Item>
                <Descriptions.Item label="Run started on">
                  {getDateTimeString(batchDetails.created_at)}
                </Descriptions.Item>
                <Descriptions.Item label="Max Parallel Requests">
                  {batchDetails.config?.max_requests}
                </Descriptions.Item>

                {batchDetails.source_type === ORCHESTRATOR ? (
                  <>
                    <Descriptions.Item label="Pipeline">
                      {batchDetails.meta_data.pipeline}
                    </Descriptions.Item>
                    <Descriptions.Item label="Wings source type ID">
                      {batchDetails.meta_data.target_source_type}
                    </Descriptions.Item>
                  </>
                ) : null}

                {batchDetails.source_type === ORCHESTRATOR &&
                  batchDetails.meta_data?.fms &&
                  batchDetails.meta_data?.request_annotation_time && (
                    <>
                      <Descriptions.Item label="Time allotted">
                        {getTimeInHoursAndMins(
                          batchDetails.meta_data?.request_annotation_time
                        )}{" "}
                        Hrs
                      </Descriptions.Item>
                    </>
                  )}
              </Descriptions>

              {batchDetails.source_type === ORCHESTRATOR && (
                <>
                  <Typography.Title level={5} style={{ marginTop: "1rem" }}>
                    Requested Features
                  </Typography.Title>
                  <span>
                    {Object.keys(batchDetails?.meta_data?.features ?? {}).map(
                      (item) => (
                        <Tag color="cyan" key={item}>
                          {item}
                        </Tag>
                      )
                    )}
                  </span>
                </>
              )}

              {batchDetails.source_type === ORCHESTRATOR &&
                batchDetails.meta_data?.step_wise && (
                  <>
                    <Typography.Title level={5} style={{ marginTop: "1rem" }}>
                      In Progress Requests Step Count
                    </Typography.Title>
                    <div>
                      {Object.entries(batchDetails.meta_data.step_wise).map(
                        (item) => (
                          <span className="margin-right">
                            {item[0]}: {item[1]}
                          </span>
                        )
                      )}
                    </div>
                  </>
                )}
            </Skeleton>
          </Col>
        </Row>

        <Divider />
        <Row className="mt">
          <Col span={22} offset={1} className="w-100">
            <Skeleton active loading={isLoading}>
              <span>
                <Typography.Title level={3}>Request Details</Typography.Title>
              </span>
              <div className="container-space-between">
                <div className="w-75">
                  <Input
                    placeholder="Search"
                    className="searchRequest"
                    value={searchData}
                    onChange={(e) => setSearchData(e.target.value)}
                    onKeyDown={searchRequest}
                    prefix={<SearchOutlined />}
                  />

                  {isSearched ? (
                    <Space size="small">
                      <Button
                        type="primary"
                        onClick={() => {
                          setIsSearched(false);
                          setSearchData("");
                          setRefreshData((prevState) => !prevState);
                        }}
                        icon={<CloseCircleOutlined />}
                        shape="circle"
                        className="ml"
                      ></Button>
                    </Space>
                  ) : null}

                  {batchDetails.meta_data?.fms && (
                    <RangePicker
                      className="monitor-filter-datetime ml"
                      showTime={{
                        defaultValue: [moment("00:00", "HH:mm")],
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      onChange={(dates, dateStrings) =>
                        handleDateChange(dates, dateStrings)
                      }
                    />
                  )}
                  <Select
                    onChange={(val) => filterData(val, FILTER.STATUS)}
                    className="monitor-filter ml"
                    placeholder="Filter by status"
                    allowClear
                  >
                    {Object.keys(REQUESTS_STATUS)
                      .filter(
                        (item) =>
                          item !== PENDING && item !== PAUSED && item !== ALL
                      )
                      .map((status) => {
                        return (
                          <Option
                            key={REQUESTS_STATUS[status]}
                            value={REQUESTS_STATUS[status]}
                          >
                            {REQUEST_STATUS_TEXT[status]}
                          </Option>
                        );
                      })}
                  </Select>

                  {batchDetails.meta_data?.fms && (
                    <Select
                      onChange={(val) => filterData(val, FILTER.QR)}
                      className="monitor-filter ml"
                      placeholder="Filter by QR"
                      allowClear
                    >
                      {Object.values(QR_STATUS_ENUMS).map((value) => {
                        return (
                          <Option key={value} value={value}>
                            {QR_STATUS[value]}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </div>
                <div>
                  <RBAC allowedRoles={[ROLE["SUPER_USER"], ROLE["STAFF_USER"]]}>
                    <Dropdown overlay={displayDropdownMenu()}>
                      <Button
                        type="default"
                        loading={requestsBtnLoading}
                        icon={<DownloadOutlined />}
                        className="ml"
                      >
                        Requests Details
                      </Button>
                    </Dropdown>
                  </RBAC>
                  <RBAC allowedRoles={[ROLE["SUPER_USER"], ROLE["STAFF_USER"]]}>
                    <Dropdown overlay={displayDropdownMenu(true)}>
                      <Button
                        type="primary"
                        loading={batchFileBtnLoading}
                        className="ml"
                        icon={<FileTextOutlined />}
                      >
                        Batch Input
                      </Button>
                    </Dropdown>
                  </RBAC>
                </div>
              </div>
              {/* {noDataFound && <h2>No Data Found..</h2>} */}

              <div>
                <RBAC allowedRoles={[ROLE["SUPER_USER"], ROLE["STAFF_USER"]]}>
                  <Checkbox
                    checked={selectedRequests.length > 0}
                    onClick={() => {
                      if (selectedRequests.length === filteredData.length) {
                        setSelectedRequests([]);
                      } else {
                        setSelectedRequests(
                          filteredData.map((item) => item.id)
                        );
                      }
                      setIsVisible(false);
                    }}
                    style={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      letterSpacing: "0.1px",
                      fontWeight: 500,
                      marginLeft: "5px",
                    }}
                  >
                    {selectedRequests.length} Selected :
                  </Checkbox>
                  <Button type="default" onClick={() => handleBulkDownload()}>
                    Download
                  </Button>

                  <Button
                    type="default"
                    onClick={() => handleBulkRerun()}
                    className="ml-2"
                  >
                    Rerun Request
                  </Button>
                </RBAC>
              </div>

              <br />

              {
                <div className="w-100">
                  <Table
                    columns={getColumnTitle()}
                    dataSource={filteredData}
                    bordered
                    scroll={{ x: true }}
                    tableLayout="fixed"
                    loading={tableLoading}
                    pagination={{
                      onChange: (page, pageSize) =>
                        setPageDetails({ pageNumber: page, pageSize }),
                      defaultPageSize: pageDetails.pageSize,
                      total: parseInt(requestCount),
                      showSizeChanger: true,
                      onShowSizeChange: (current, size) =>
                        setPageDetails({
                          pageNumber: current,
                          pageSize: size,
                        }),
                      current: pageDetails.pageNumber,
                    }}
                  />
                </div>
              }
            </Skeleton>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Monitor);
