export const CREATE_BATCH_REQUEST = "/batch/";
export const CREATE_RERUN_BATCH_REQUEST = "/batch/create_rerun_batch/";
export const NEAR_MAP_SINGLE = "/services/parcel_nearmap_validation/";
export const FETCH_PARCEL = "/services/fetch_parcels/";
export const LIST_ALL_BATCHES = "/batch/";
export const LIST_CREATED_BATCHES = "/batch?status=1";
export const LIST_RUNNING_BATCHES = "/batch?status=2";
export const LIST_COMPLETED_BATCHES = "/batch?status=3";
export const LIST_PAUSED_BATCHES = "/batch?status=5";
export const LIST_FAILED_BATCHES = "/batch?status=4";

export const SINGLE_BATCH = "single_batch";
export const SINGLE_BATCH_REQUESTS = "single_batch_request";
export const PAUSE_BATCH = "pause_batch";
export const RESUME_BATCH = "resume_batch";
export const EXPORT_BATCH = "export_batch";
export const EXECUTE_BATCH = "execute_batch";

export const SINGLE_FILE_UPLOAD = "request/%s/upload/";
export const SINGLE_FILE_DOWNLOAD = "request/%s/request_download/";
export const GET_ALL_FEATURES = "/services/features";
export const GET_ALL_PIPELINE = "/orchestrator/services/pipelines";
export const GET_ALL_WINGS_SOURCE_TYPE =
  "/orchestrator/services/target_source_types";

export const GET_ORCHESTRATOR_METADATA = "/orchestrator/services/";
export const UPDATE_REQUEST_META_DATA =
  "/orchestrator/services/update_meta_data/";
export const UPDATE_MAX_PARALLEL_REQUESTS = "/update_max_parallel_requests/";
export const CONVERT_GEOJSON_TO_CSV = "/batch/convert_geojson_to_csv/";
export const GET_FAILED_REQUESTS_BATCH =
  "/batch/create_failed_requests_batch_file/";
export const CREATE_REQUESTS_BATCH_FILE = "/batch/create_requests_file/";

export const LOGIN = "/auth/login/";

export const AUTH_ERROR = "AuthenticationFailed";

export const STEP_NAMES = "orch/steps/";

export const TEAMS_NAMES = "wings/teams/";

export const BULK_DOWNLOAD = "request/bulk_request_download/";
export const BULK_RERUN_REQUESTS = "request/rerun_requests/";

export const GET_WINGS_SOURCE_STATS = "/orch/source_info/";

export const generateApiURL = (type, id) => {
  switch (type) {
    case SINGLE_BATCH:
      return `/batch/${id}`;
    case SINGLE_BATCH_REQUESTS:
      return `/batch/${id}/requests/`;
    case EXECUTE_BATCH:
      return `/batch/${id}/execute/`;
    case PAUSE_BATCH:
      return `/batch/${id}/pause/`;
    case RESUME_BATCH:
      return `/batch/${id}/resume/`;
    case EXPORT_BATCH:
      return `/batch/${id}/export`;
    case UPDATE_MAX_PARALLEL_REQUESTS:
      return `/batch/${id}${UPDATE_MAX_PARALLEL_REQUESTS}`;

    default:
      return "";
  }
};
