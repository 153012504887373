import React from "react";
import { Result } from "antd";

const NoDataFound = (props) => {
  const { subTitle, status, title } = props;
  return (
    <Result status={status} title={title} subTitle={subTitle} {...props} />
  );
};

export default NoDataFound;
