import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import React, { useCallback, useState } from "react";

const CopyText = ({ text }) => {
  const [copied, setCopied] = useState(false);
  const handleClick = useCallback(() => {
    setCopied((prev) => !prev);
    window.navigator.clipboard.writeText(text);
    setTimeout(() => setCopied((prev) => !prev), 1000);
  }, [copied, setCopied]);

  return (
    <div style={{ display: "flex" }}>
      <span
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </span>
      <span
        onClick={handleClick}
        style={{
          cursor: "pointer",
          display: copied ? "none" : "",
          marginLeft: "0.2rem",
        }}
      >
        <CopyOutlined />
      </span>
      <span style={{ display: copied ? "" : "none", marginLeft: "0.2rem" }}>
        <CheckOutlined style={{ color: "#00a854" }} />
      </span>
    </div>
  );
};

export default CopyText;
